import React from 'react';
import { IconArrowRight } from '../../../../aurora/icons';
import ActionButton from '../../../../components/table/ActionButton';
import { JourneyEntity } from '../../../../state/journeys/types';
import ExportJourneyButton from '../export/ExportJourneyButton';
import styles from './JourneyTableAction.module.scss';

interface Props {
  journey: JourneyEntity;
  isArtycViewer: boolean;
}
const JourneyTableAction = ({ journey, isArtycViewer }: Props) => {
  return (
    <div className={styles.row}>
      <ActionButton
        icon={IconArrowRight}
        href={`/portal/journey/${journey._id}`}
        label="View Shipment"
      />
      <ExportJourneyButton
        journey={journey}
        isArtycViewer={isArtycViewer}
        iconOnly={true}
      />
    </div>
  );
};

export default JourneyTableAction;

import React, { useState } from 'react';
import Checkbox from '../../../aurora/components/Checkbox/Checkbox';
import styles from './MultiSelectFilter.module.scss';

interface Props<T> {
  options: T[] | null;
  displayOptions: string[];
  onChange: (selectedOptions: T[]) => void;
  selectedOptions: T[];
}

// why does this need to extend string/number?
const MultiSelectCheckboxList = <T extends string>({
  options,
  displayOptions,
  onChange,
  selectedOptions,
}: Props<T>) => {
  const initialOptionMap = selectedOptions.reduce((map, option) => {
    map[option] = true;
    return map;
  }, {} as Record<T, boolean>);

  const [optionMap, setOptionMap] = useState(initialOptionMap);

  const setOption = (option: T, isChecked: boolean) => {
    const newOptionMap = {
      ...optionMap,
      [option]: isChecked,
    };

    setOptionMap(newOptionMap);

    const selected = Object.keys(newOptionMap).filter(
      (option) => newOptionMap[option as T]
    ) as T[];
    onChange(selected);
  };

  const optionsList = options ? (
    options.map((option, index) => (
      <Checkbox
        key={option}
        label={displayOptions[index]}
        onChange={(isChecked) => setOption(option, isChecked)}
        defaultChecked={selectedOptions.includes(option)}
        labelClassName={styles.checkbox}
      />
    ))
  ) : (
    <p>Loading...</p>
  );

  return <div className={styles.optionsList}>{optionsList}</div>;
};

export default MultiSelectCheckboxList;

import React from 'react';
import StatusBadge, { StatusBadgeStatus } from '../StatusBadge/StatusBadge';
import styles from './FilterToggleGroup.module.scss';

export interface FilterOption<T> {
  value: T;
  label: string;
  badgeStatus?: StatusBadgeStatus;
}

interface Props<T> {
  items: Array<Record<string, any>>;
  options: FilterOption<T>[];
  selectedValue: T | 'all';
  filterKey: string;
  onValueChange: (value: T | 'all') => void;
}

interface FilterTileProps<T> {
  value: T | 'all';
  label: string;
  count: number;
  isSelected: boolean;
  onClick: () => void;
  badgeStatus?: StatusBadgeStatus;
}

const FilterTile = <T,>({
  value,
  label,
  count,
  isSelected,
  onClick,
  badgeStatus,
}: FilterTileProps<T>) => (
  <div
    key={String(value)}
    className={`${styles.tile} ${isSelected ? styles.selected : ''}`}
    onClick={onClick}
  >
    <div className={styles.tileContent}>
      {badgeStatus ? (
        <StatusBadge content={label} status={badgeStatus} />
      ) : (
        <span className={styles.label}>{label}</span>
      )}
      <span className={styles.count}>{count}</span>
    </div>
  </div>
);

const FilterToggleGroup = <T,>({
  items,
  options,
  selectedValue,
  filterKey,
  onValueChange,
}: Props<T>): React.ReactElement => {
  const getCountForValue = (value: T | 'all') => {
    if (value === 'all') {
      return items.length;
    }
    return items.filter((item) => item[filterKey] === value).length;
  };

  return (
    <div className={styles.group}>
      <FilterTile
        value="all"
        label="All"
        count={getCountForValue('all')}
        isSelected={selectedValue === 'all'}
        onClick={() => onValueChange('all')}
      />

      {options.map((option) => (
        <FilterTile
          key={String(option.value)}
          value={option.value}
          label={option.label}
          count={getCountForValue(option.value)}
          isSelected={selectedValue === option.value}
          onClick={() => onValueChange(option.value)}
          badgeStatus={option.badgeStatus}
        />
      ))}
    </div>
  );
};

export default FilterToggleGroup;

import { JourneyTypeEnum } from '../journeys/types';

export enum SalesModelEnum {
  CaaS = 'Cooling as a Service',
  Lease = 'Lease',
  Sample = 'Sample',
}

export interface CustomKitting {
  name: string;
}

export interface CompanyEntity {
  _id: string;
  companyName: string;
  salesModel: SalesModelEnum;
  defaultJourneyType: JourneyTypeEnum;
  kittings?: CustomKitting[];
}

export interface CompanyFilters {
  search?: string;
}

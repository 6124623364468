export type Validator = (value: string | undefined) => string | undefined;

export const required: Validator = (value) => (value ? undefined : 'Required');

export const validatePostalCode: Validator = (value) => {
  if (!value) return 'Required';
  const postalCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return postalCodePattern.test(value) ? undefined : 'Invalid postal code';
};

export const validatePhoneNumber: Validator = (value) => {
  if (!value) return undefined;

  // Allow common phone number formats:
  // (123) 456-7890
  // 123-456-7890
  // 123.456.7890
  // 1234567890
  const phonePattern =
    /^(\+1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  if (!phonePattern.test(value)) {
    return 'Invalid Phone number';
  }

  return undefined;
};

export const composeValidators =
  (...validators: Validator[]) =>
  (value: string | undefined) =>
    validators.reduce(
      (error: string | undefined, validator) => error || validator(value),
      undefined
    );

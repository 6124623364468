import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconHandle: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase width={4} height={16} viewBox="0 0 4 16" {...props}>
    <Path
      d="M3.2 0.800003L3.2 15.2L0.799996 15.2L0.799997 0.800003L3.2 0.800003Z"
      fill="currentColor"
    />
  </IconBase>
);

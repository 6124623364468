import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { OrderEntity } from '../../../../state/orders/types';
import Slideout from '../../../../aurora/components/Slideout/Slideout';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import { JourneyEntity } from '../../../../state/journeys/types';
import JourneysApi from '../../../../api/journeysApi';
import OrderInfoSlideoutContent from './OrderInfoSlideoutContent';
import { setShipmentsForJourney } from '../../../../state/journeys';
import ShippingProfilesApi from '../../../../api/shippingProfilesApi';
import { ShippingProfileEntity } from '../../../../state/shippingProfile/types';
import { RootState } from '../../../../state/store';
import LoadingSpinner from '../../../../components/layout/LoadingSpinner';

interface Props {
  order: OrderEntity | undefined;
  open: boolean;
  closeSlideout: () => void;
  isArtycViewer: boolean;
}
const OrderInfoSlideout = ({
  order,
  open,
  closeSlideout,
  isArtycViewer,
}: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [journey, setJourney] = useState<JourneyEntity | null>(null);
  const [shippingProfile, setShippingProfile] = useState<
    ShippingProfileEntity | undefined
  >(undefined);

  // this only works right now since we fetch all companies. will have issues when we lazy load
  const company = useSelector((state: RootState) =>
    state.companies.data.find((company) => company._id === order?.company)
  );

  const fetchData = async () => {
    if (order === undefined) {
      return;
    }

    setIsSubmitting(true);
    try {
      if (order.journeyId) {
        const journeyResp = await JourneysApi.getJourney(
          axios,
          auth,
          order.journeyId
        );
        setJourney(journeyResp);

        const shipments = await JourneysApi.getShipmentsForJourney(
          axios,
          auth,
          journeyResp._id
        );
        dispatch(
          setShipmentsForJourney({ journey: journeyResp._id, shipments })
        );

        const shippingProfileResp = journeyResp.shippingProfile
          ? await ShippingProfilesApi.getShippingProfile(
              axios,
              auth,
              journeyResp.shippingProfile
            )
          : undefined;
        setShippingProfile(shippingProfileResp);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [order]);

  // TODO(order): fix for lease orders, which won't have journey
  if (
    order === undefined ||
    company === undefined ||
    (!isSubmitting && journey === null)
  ) {
    return null;
  }

  const footerButton = (
    <Button
      variant="primary"
      label="View Journey"
      href={`/portal/journey/${order.journeyId}`}
    />
  );

  return (
    <Slideout
      open={open}
      onClose={closeSlideout}
      title="Order Summary"
      footer={footerButton}
    >
      {isSubmitting || journey === null ? (
        <LoadingSpinner height={32} width={32} />
      ) : (
        <OrderInfoSlideoutContent
          isArtycViewer={isArtycViewer}
          order={order}
          journey={journey}
          company={company}
          shippingProfile={shippingProfile}
        />
      )}
    </Slideout>
  );
};

export default OrderInfoSlideout;

import React, { useState } from 'react';
import Slideout from '../../../../aurora/components/Slideout/Slideout';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import { Form } from 'react-final-form';
import { createFormToBody, OrderFormData } from './mapOrderData';
import { selectAuth } from '../../../../state/auth';
import { showToast } from '../../../../aurora/components/Toast/Toast';
import formStyles from '../../../../components/forms/Form.module.scss';
import CaasOrderForm from './CaasOrderForm';
import { FormApi } from 'final-form';
import OrdersApi from '../../../../api/ordersApi';
import { loadOrders } from '../../../../state/orders';
import { sanitizePhoneNumber } from '../../../../utils/addressUtil';

interface Props {
  open: boolean;
  closeSlideout: () => void;
  isArtycViewer: boolean;
}
const CaasOrderFormSlideout = ({
  open,
  closeSlideout,
  isArtycViewer,
}: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const ordersState = useSelector((state: RootState) => state.orders);

  const [submitting, setSubmitting] = useState(false);

  const onClose = (form: FormApi<OrderFormData>) => {
    form.reset();
    closeSlideout();
  };

  const onSubmit = async (
    data: OrderFormData,
    form: FormApi<OrderFormData>
  ) => {
    setSubmitting(true);

    try {
      // Sanitize phone numbers before creating body
      const sanitizedData = {
        ...data,
        user: {
          ...data.user,
          phoneNumber:
            data.user.phoneNumber != null
              ? sanitizePhoneNumber(data.user.phoneNumber)
              : undefined,
        },
        lab: {
          ...data.lab,
          phoneNumber:
            'phoneNumber' in data.lab && data.lab.phoneNumber != null
              ? sanitizePhoneNumber(data.lab.phoneNumber)
              : undefined,
        },
      };

      const body = createFormToBody(sanitizedData);
      await OrdersApi.createOrder(axios, auth, body);

      const action = await loadOrders(axios, auth!, ordersState);
      dispatch(action);

      showToast({
        type: 'success',
        title: 'Success',
        text: 'Successfully created order',
      });

      onClose(form);
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'An error occurred. Please try again or contact support',
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <Slideout
          open={open}
          onClose={() => onClose(form)}
          title="Create New Order"
          footer={
            <div className={formStyles.formRow}>
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => onClose(form)}
                disabled={submitting}
              />
              <Button
                variant="primary"
                label="Create"
                onClick={handleSubmit}
                disabled={submitting}
              />
            </div>
          }
        >
          <CaasOrderForm isArtycViewer={isArtycViewer} />
        </Slideout>
      )}
    />
  );
};

export default CaasOrderFormSlideout;

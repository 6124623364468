import { DeviceType } from '../devices/types';

export enum ProfileType {
  Standard = 'Standard',
  Custom = 'Custom',
}

export interface ShippingProfileEntity {
  _id: string;
  company: string;
  name: string;
  type: ProfileType;
  shipperType: DeviceType;
  active: boolean;
  archived: boolean;
  measurementIntervalSec: number;
  transmissionIntervalSec: number;
  minSteadyStateTempC: number;
  maxSteadyStateTempC: number;
  steadyStateWindowSec: number;
  cooldownWindowSec: number;
  minCriticalTempC?: number | null;
  maxCriticalTempC?: number | null;
  notes?: string | null;
}

import React, { useEffect } from 'react';
import TextField from '../../../../components/forms/TextField';
import SelectField from '../../../../components/forms/SelectField';
import ShippingProfileSelect from './ShippingProfileSelect';
import { required } from '../../../../components/forms/validators';
import formStyles from '../../../../components/forms/Form.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import AddressFormSection from './AddressFormSection';
import { companyOptions } from './formOptions';
import ExpectedDeliveryDate from './ExpectedDeliveryDate';
import { useForm } from 'react-final-form';
import { isCompanyUser, selectAuth } from '../../../../state/auth';
import { AddressTypeEnum } from '../../../../state/addresses/types';
import { DeviceType } from '../../../../state/devices/types';

// TODO(order): allow initial values so this can be used by EditOrderButton
interface Props {
  isArtycViewer: boolean;
}
// TODO(order): support other shipper types
const CreateCaasOrderForm = ({ isArtycViewer }: Props) => {
  const auth = useSelector(selectAuth);

  const companies = useSelector((state: RootState) => state.companies.data);

  const form = useForm();
  // set company in form for company users
  useEffect(() => {
    if (auth !== null) {
      if (isCompanyUser(auth)) {
        form.change('company', auth.companyId);
      }
    }
  }, [auth]);

  return (
    <div className={formStyles.form}>
      <TextField
        name="poNumber"
        label="P.O Number"
        showOptional
        tooltip="The purchase order number associated with this sales order"
      />
      <TextField
        name="customId"
        label="Custom ID"
        showOptional
        tooltip="Enter a unique identifier specific to your internal tracking needs. Could be a patient ID, a project ID, etc"
      />
      {isArtycViewer ? (
        <SelectField
          name="company"
          options={companyOptions(companies)}
          label="Company"
          validate={required}
        />
      ) : null}
      <ShippingProfileSelect shipperType={DeviceType.MedstowMicro} />
      <ExpectedDeliveryDate isArtycViewer={isArtycViewer} />
      <AddressFormSection
        heading="Leg 1: User Address"
        isArtycViewer={isArtycViewer}
        shipmentPrefix="user"
        includeTracking={false}
        addressType={AddressTypeEnum.USER}
      />
      <AddressFormSection
        heading="Leg 2: Lab Address"
        isArtycViewer={isArtycViewer}
        shipmentPrefix="lab"
        includeTracking={false}
        addressType={AddressTypeEnum.LAB}
      />
    </div>
  );
};

export default CreateCaasOrderForm;

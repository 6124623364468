import { AddressEntity } from '../state/addresses/types';

export const toAddressString = (address: AddressEntity) => {
  if (address.addressName) {
    return address.addressName;
  }

  const attn = address.attn ? `${address.attn}, ` : '';
  const addressLine2 = address.addressLine2 ? `, ${address.addressLine2}` : '';
  return `${attn}${address.addressLine1}${addressLine2}, ${address.city}, ${address.state} ${address.postalCode}`;
};

export const sanitizePhoneNumber = (
  phone: string | undefined | null
): string | undefined => {
  if (phone === undefined || phone === null) {
    return undefined;
  }
  return phone.replace(/\D/g, '');
};

export const formatPhoneNumber = (phone: string | undefined): string => {
  if (!phone) return '';

  // Remove all non-digits
  const cleaned = phone.replace(/\D/g, '');

  // Format as (XXX) XXX-XXXX
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // If it doesn't match the expected format, return the cleaned number
  return cleaned;
};

import { DeviceType } from '../devices/types';

export enum SegmentStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export type SnapshotMode = 'all' | 'artyc' | 'customer';
export type ArtycSnapshotMode = Omit<SnapshotMode, 'customer'>;

export interface SegmentEntity {
  _id: string;
  createdAt: string;
  journey: string;
  status: SegmentStatus;
  startTime: string;
  endTime: string | null;
  lengthSec: number | null;
  firstSync?: object | null;
  hasGeoData: boolean;
  deviceType: DeviceType;
  serialNumber: string;
  company: string;
  companyName: string;
  firmwareVersion: string;
  setTemperature: number;
  logIncrement: number;
  shippingProfile?: string;
  destinations?: string[];
  destinationConfirmations?: string[];
}

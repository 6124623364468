import { AddressEntity } from '../../../../state/addresses/types';
import {
  formatPhoneNumber,
  sanitizePhoneNumber,
} from '../../../../utils/addressUtil';
import { AddressFormData } from './types';

export const mapEntityToFormData = (entity: AddressEntity): AddressFormData => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { addressType, country, ...addressFields } = entity;

  return {
    addressType,
    address: {
      ...addressFields,
      phoneNumber:
        formatPhoneNumber(addressFields.phoneNumber as string | undefined) ||
        '',
    },
  };
};

export const mapFormDataToEntity = (
  formData: AddressFormData
): Omit<AddressEntity, '_id'> => {
  const { addressType, address } = formData;
  return {
    addressType,
    ...address,
    phoneNumber: sanitizePhoneNumber(address.phoneNumber),
    country: 'US',
  };
};

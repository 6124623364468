import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FleetViewDevice, FleetViewFilterKeys, FleetViewSort } from './types';
import { DeviceDeploymentStatus } from '../devices/types';

interface FleetViewState {
  selectedSerialNumber: string | null;
  selectedCompany: string | null;
  devices: FleetViewDevice[];
  searchQuery?: string;
  filter: { [K in keyof typeof FleetViewFilterKeys]: boolean };
  sort: FleetViewSort;
  selectedDeploymentStatus: DeviceDeploymentStatus | null;
}

const initialState: FleetViewState = {
  selectedSerialNumber: null,
  selectedCompany: null,
  devices: [],
  filter: Object.fromEntries(
    Object.keys(FleetViewFilterKeys).map((key) => [key, true])
  ) as { [K in keyof typeof FleetViewFilterKeys]: boolean },
  sort: FleetViewSort.UPDATED_DESC,
  selectedDeploymentStatus: null,
};

const fleetViewSlice = createSlice({
  name: 'fleetView',
  initialState,
  reducers: {
    setSelectedSerialNumber(state, action: PayloadAction<string | null>) {
      state.selectedSerialNumber = action.payload;
    },
    setSelectedCompany(state, action: PayloadAction<string | null>) {
      state.selectedCompany = action.payload;
    },

    setDevices(state, action: PayloadAction<FleetViewDevice[]>) {
      state.devices = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string | undefined>) {
      state.searchQuery = action.payload;
    },
    toggleFilter(
      state,
      action: PayloadAction<{ filter: keyof typeof FleetViewFilterKeys }>
    ) {
      const newFilter = {
        ...state.filter,
        [action.payload.filter]: !state.filter[action.payload.filter],
      };
      state.filter = newFilter;
    },
    setSort(state, action: PayloadAction<FleetViewSort>) {
      state.sort = action.payload;
    },
    setSelectedDeploymentStatus(
      state,
      action: PayloadAction<DeviceDeploymentStatus | null>
    ) {
      state.selectedDeploymentStatus = action.payload;
    },
  },
});

export const {
  setSelectedSerialNumber,
  setSelectedCompany,
  setDevices,
  setSearchQuery,
  toggleFilter,
  setSort,
  setSelectedDeploymentStatus,
} = fleetViewSlice.actions;

export default fleetViewSlice.reducer;

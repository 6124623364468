import { AxiosInstance } from 'axios';
import { AddressEntity, AddressTypeEnum } from '../state/addresses/types';
import { Auth } from '../state/auth/types';
import { DataPayload } from '../state/types';
import { genericFetch } from './api';

const ARTYC_ADDRESSES_URL = '/artyc/addresses';
const companyAddressesUrl = (companyId: string) =>
  `/api/v1/company/${companyId}/addresses`;

const AddressesApi = {
  createAddress: async (
    axios: AxiosInstance,
    auth: Auth | null,
    address: Omit<AddressEntity, '_id'>,
    companyId: string
  ): Promise<AddressEntity> => {
    const response = await axios.post(companyAddressesUrl(companyId), address);
    return response.data;
  },

  updateAddress: async (
    axios: AxiosInstance,
    auth: Auth | null,
    addressId: string,
    address: Omit<AddressEntity, '_id'> & { lastModifiedBy: string },
    companyId: string
  ): Promise<AddressEntity> => {
    const response = await axios.put(
      `${companyAddressesUrl(companyId)}/${addressId}`,
      address
    );
    return response.data;
  },

  deleteAddress: async (
    axios: AxiosInstance,
    auth: Auth | null,
    addressId: string,
    companyId: string
  ): Promise<AddressEntity> => {
    const response = await axios.delete(
      `${companyAddressesUrl(companyId)}/${addressId}`
    );
    return response.data;
  },

  getCompanyAddresses: async (
    axios: AxiosInstance,
    auth: Auth | null,
    companyId: string,
    params: {
      limit: number;
      page: number;
      sortColumn?: string;
      sortDirection?: number;
      filters?: {
        addressTypes?: AddressTypeEnum[];
        search?: string;
      };
    }
  ): Promise<DataPayload<AddressEntity>> => {
    const response = await axios.get(companyAddressesUrl(companyId), {
      params,
    });
    return response.data;
  },

  getLabAddresses: async (
    axios: AxiosInstance,
    auth: Auth | null,
    companyId: string
  ): Promise<DataPayload<AddressEntity>> => {
    const response = await axios.get(companyAddressesUrl(companyId), {
      params: {
        limit: 1000, // TODO: Remove pagination once backend supports it
        page: 0,
        filters: {
          addressTypes: [AddressTypeEnum.LAB],
        },
      },
    });
    return response.data;
  },

  getFleetViewAddresses: async (
    axios: AxiosInstance,
    auth: Auth | null
  ): Promise<DataPayload<AddressEntity>> => {
    const response = await genericFetch<DataPayload<AddressEntity>>(
      axios,
      auth,
      ARTYC_ADDRESSES_URL,
      companyAddressesUrl(auth?.companyId || ''),
      {
        paginate: false,
        filters: {
          addressTypes: [AddressTypeEnum.WAREHOUSE, AddressTypeEnum.LAB],
        },
      }
    );
    return response;
  },
};

export default AddressesApi;

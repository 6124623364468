import React, { useEffect, useState } from 'react';
import ProfileCard from './ProfileCard';
import {
  ProfileType,
  ShippingProfileEntity,
} from '../../../../state/shippingProfile/types';
import { useDispatch, useSelector } from 'react-redux';
import { setProfiles } from '../../../../state/shippingProfile';
import { RootState } from '../../../../state/store';
// import CreateProfileCard from './CreateProfileCard';
import Slideout from '../../../../aurora/components/Slideout/Slideout';
import ShippingProfileForm from './ShippingProfileForm';
import ShippingProfilesApi from '../../../../api/shippingProfilesApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { Text } from '../../../../aurora/typography/Text/Text';
import { showToast } from '../../../../aurora/components/Toast/Toast';
import { useRollbar } from '@rollbar/react';
import styles from './ShippingProfilesContainer.module.scss';
import { SalesModelEnum } from '../../../../state/companies/types';
import { DeviceType } from '../../../../state/devices/types';
import {
  ShippingProfileFormData,
  shippingProfileFormDataToEntity,
} from './mapShippingProfileData';

interface Props {
  selectedType: 'all' | 'custom' | 'standard';
  selectedCompanyId: string;
}

// TODO(company-profile): turn back on delete button -- make it archive instead
const ShippingProfilesContainer = ({
  selectedType,
  selectedCompanyId,
}: Props) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const rollbar = useRollbar();

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [slideoutOpen, setSlideoutOpen] = useState(false);
  const [slideoutProfile, setSlideoutProfile] =
    useState<null | ShippingProfileEntity>(null);
  const [formKey, setFormKey] = useState(Date.now());

  const companyShipperTypes =
    useSelector((state: RootState) => state.devices.deviceTypeList) || [];
  const isSelectedCompanyCaas = useSelector((state: RootState) => {
    const company = state.companies.data.find(
      (company) => company._id === selectedCompanyId
    );
    return company !== undefined && company.salesModel == SalesModelEnum.CaaS;
  });

  const handleSlideoutClose = () => {
    setSlideoutOpen(false);
    setFormKey(Date.now());
  };

  const changeProfile = async (
    change: () => Promise<any>,
    successMessage: string,
    errorMessage: string
  ) => {
    try {
      setUpdating(true);
      await change();
      showToast({
        type: 'success',
        title: 'Success!',
        text: successMessage,
      });

      await loadProfiles();
      handleSlideoutClose();
    } catch (error) {
      rollbar.error(errorMessage, error as Error);
      showToast({
        type: 'error',
        title: 'Error',
        text: errorMessage,
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleSaveProfile = async (profileData: ShippingProfileFormData) => {
    await changeProfile(
      () =>
        ShippingProfilesApi.createShippingProfile(
          axios,
          auth,
          shippingProfileFormDataToEntity(profileData)
        ),
      'New Shipping Profile Created.',
      'New Shipping Profile could not be created.'
    );
  };

  const handleEditProfile = async (profileData: ShippingProfileFormData) => {
    await changeProfile(
      () =>
        ShippingProfilesApi.editShippingProfile(
          axios,
          auth,
          shippingProfileFormDataToEntity(profileData)
        ),
      'Shipping Profile Updated.',
      'Shipping Profile could not be updated.'
    );
  };

  const handleToggleActive = async (profile: ShippingProfileEntity) => {
    await changeProfile(
      () =>
        ShippingProfilesApi.toggleShippingProfileActive(
          axios,
          auth,
          profile._id
        ),
      'Shipping Profile Active Toggled.',
      'Shipping Profile could not be toggled.'
    );
  };

  const profiles = useSelector(
    (state: RootState) => state.shippingProfiles.profiles
  );

  const loadProfiles = async () => {
    setLoading(true);

    const profiles = await ShippingProfilesApi.getShippingProfiles(
      axios,
      auth,
      selectedCompanyId
    );
    dispatch(setProfiles(profiles));

    setLoading(false);
  };

  useEffect(() => {
    loadProfiles();
  }, [selectedCompanyId]);

  if (loading) {
    return (
      <Text size="sm" color="strong900" sx={{ marginTop: '24px' }}>
        Loading...
      </Text>
    );
  }

  return (
    <div className={styles.container}>
      {/* <CreateProfileCard
        onCreate={() => {
          setSlideoutOpen(true);
          setSlideoutProfile(null);
        }}
      /> */}
      {profiles
        .filter((profile) =>
          selectedType === 'all'
            ? true
            : selectedType === 'custom'
            ? profile.type === ProfileType.Custom
            : profile.type === ProfileType.Standard
        )
        .filter((profile) => {
          // for now CaaS only supports Medstow Micro
          if (isSelectedCompanyCaas) {
            return profile.shipperType === DeviceType.MedstowMicro;
          } else {
            // for lease companies, show the profiles for the units they have
            return companyShipperTypes.includes(profile.shipperType);
          }
        })
        .map((profile) => (
          <ProfileCard
            key={profile._id}
            profile={profile}
            onEdit={() => {
              setSlideoutOpen(true);
              setSlideoutProfile(profile);
            }}
            onToggleActive={() => handleToggleActive(profile)}
          />
        ))}
      <Slideout
        open={slideoutOpen}
        onClose={handleSlideoutClose}
        title={slideoutProfile ? 'Edit Profile' : 'Create Profile'}
      >
        {slideoutProfile === null ? (
          <ShippingProfileForm
            key={formKey}
            selectedCompanyId={selectedCompanyId}
            onSave={handleSaveProfile}
            updating={updating}
          />
        ) : (
          <ShippingProfileForm
            key={formKey}
            selectedCompanyId={selectedCompanyId}
            profile={slideoutProfile}
            onSave={handleEditProfile}
            updating={updating}
          />
        )}
      </Slideout>
    </div>
  );
};

export default ShippingProfilesContainer;

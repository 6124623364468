import React from 'react';
import { Text } from '../../../aurora/typography/Text/Text';
import styles from './SectionBar.module.scss';

interface SectionOption {
  name: string;
  displayText: string;
}

interface Props {
  selectedSection: string;
  setSelectedSection: (section: string) => void;
  sections: SectionOption[];
}

const SectionBar = ({
  selectedSection,
  setSelectedSection,
  sections,
}: Props) => {
  return (
    <div className={styles.container}>
      {sections.map((section) => (
        <div
          key={section.name}
          className={`${styles.section} ${
            selectedSection === section.name ? styles.selectedSection : ''
          }`}
          onClick={() => setSelectedSection(section.name)}
        >
          <Text size="sm">{section.displayText}</Text>
        </div>
      ))}
    </div>
  );
};

export default SectionBar;

import { AxiosInstance } from 'axios';
import { Auth } from '../state/auth/types';
import { genericFetch, genericPost, genericPatch } from './api';
import { ShippingProfileEntity } from '../state/shippingProfile/types';

const ARTYC_SHIPPING_PROFILES_URL = '/artyc/shipping-profiles';
const companyShippingProfilesUrl = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/shipping-profiles`;

const ShippingProfilesApi = {
  getShippingProfiles: (
    axios: AxiosInstance,
    auth: Auth | null,
    companyId: string
  ) => {
    return genericFetch<ShippingProfileEntity[]>(
      axios,
      auth,
      ARTYC_SHIPPING_PROFILES_URL,
      companyShippingProfilesUrl(auth),
      {
        companyId,
      }
    );
  },
  getShippingProfile: async (
    axios: AxiosInstance,
    auth: Auth | null,
    shippingProfileId: string
  ): Promise<ShippingProfileEntity> => {
    const response: ShippingProfileEntity = await genericFetch(
      axios,
      auth,
      `${ARTYC_SHIPPING_PROFILES_URL}/${shippingProfileId}`,
      `${companyShippingProfilesUrl(auth)}/${shippingProfileId}`
    );
    return response;
  },
  createShippingProfile: async (
    axios: AxiosInstance,
    auth: Auth | null,
    data: Omit<ShippingProfileEntity, '_id'>
  ) => {
    return genericPost(
      axios,
      auth,
      `${ARTYC_SHIPPING_PROFILES_URL}`,
      `${companyShippingProfilesUrl(auth)}`,
      data
    );
  },
  editShippingProfile: async (
    axios: AxiosInstance,
    auth: Auth | null,
    data: ShippingProfileEntity
  ) => {
    const _id = data._id;
    return genericPatch(
      axios,
      auth,
      `${ARTYC_SHIPPING_PROFILES_URL}/${_id}`,
      `${companyShippingProfilesUrl(auth)}/${_id}`,
      data
    );
  },
  toggleShippingProfileActive: async (
    axios: AxiosInstance,
    auth: Auth | null,
    _id: string
  ) => {
    return genericPatch(
      axios,
      auth,
      `${ARTYC_SHIPPING_PROFILES_URL}/${_id}/active`,
      `${companyShippingProfilesUrl(auth)}/${_id}/active`
    );
  },
};

export default ShippingProfilesApi;

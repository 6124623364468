import React, { useState, useRef, useEffect } from 'react';
import { IconMoreLine } from '../../aurora/icons';
import {
  Button,
  ButtonStyle,
  ButtonType,
  ButtonSize,
} from '../../aurora/components/Button/Button';
import styles from './ActionMenu.module.scss';

interface ActionMenuProps<T> {
  entity: T;
  actions: { label: string; onClick: (entity: T) => void }[];
}

const ActionMenu = <T,>({ entity, actions }: ActionMenuProps<T>) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleOpen = () => setOpen(!open);

  return (
    <div className={styles.actionMenu} ref={ref}>
      <Button
        onClick={toggleOpen}
        className={open ? styles.active : ''}
        type={ButtonType.Neutral}
        style={ButtonStyle.Ghost}
        size={ButtonSize.XXSmall}
        label=""
        iconLeft={IconMoreLine}
      />
      {open && (
        <div className={styles.popover}>
          {actions.map((action, index) => (
            <div key={index} className={styles.optionContainer}>
              <div
                className={styles.option}
                onClick={() => {
                  action.onClick(entity);
                  setOpen(false);
                }}
              >
                {action.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActionMenu;

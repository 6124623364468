import { EntityState } from '../types';

export enum ShipperEnvSwitchStatus {
  PENDING = 'pending',
  SHIPPER_UPDATED = 'shipperUpdated',
  SWITCH_COMPLETE = 'switchComplete',
}

interface LastKnownLocation {
  lat: number;
  long: number;
  timestamp: string;
}

interface LastKnownSensorData {
  battery: number;
  tempC: number;
  timestamp: string;
}

export enum EnvEnum {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export enum DeviceType {
  MedstowMicro = 'Medstow Micro',
  Medstow5LI = 'Medstow 5L-I',
}

export enum DeviceDeploymentStatus {
  DEPLOYED = 'Deployed',
  ON_STANDBY = 'On Standby',
  READY = 'Ready',
}

interface LastHealthCheck {
  pluggedInAt: string;
  coolingPass: boolean | null;
  fullyCharged: boolean | null;
  cellConnected: boolean | null;
  gpsConnected: boolean | null;
  wifiConnected: boolean | null;
}

export interface DeviceEntity {
  _id: string;
  deviceType: DeviceType;
  serialNumber: string;
  companyName: string;
  companyId: string;
  pcbNumber: string;
  boardVersion: string;
  macAddress: string;
  currentEnvironment: EnvEnum;
  envSwitchStatus: ShipperEnvSwitchStatus;
  mostRecentJourney: string;
  deviceDeploymentStatus: DeviceDeploymentStatus;
  lastKnownLocation: LastKnownLocation;
  lastKnownSensorData: LastKnownSensorData;
  newEnvironment?: EnvEnum | null;
  isInCurrentEnv: boolean;
  firmwareVersion?: string;
  lastHealthCheck?: LastHealthCheck;
  cellEnabled: boolean;
  updatedAt: string;
}

export interface DeviceFilters {
  search?: string;
  companyIds?: string[];
  deviceTypes?: DeviceType[];
}

export interface DeviceState extends EntityState<DeviceEntity> {
  selectedFilters?: DeviceFilters;
  deviceTypeList: DeviceType[] | null;
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../../../../aurora/components/TextInput/TextInput';
import { IconSearch } from '../../../../aurora/icons';
import {
  setSearchQuery,
  setSelectedDeploymentStatus,
} from '../../../../state/fleetView';
import styles from './DetailsHeader.module.scss';
import FleetViewFilter from './FleetViewFilter';
import FleetViewSort from './FleetViewSort';
import FilterToggleGroup, {
  FilterOption,
} from '../../../../aurora/components/FilterToggleGroup/FilterToggleGroup';
import { DeviceDeploymentStatus } from '../../../../state/devices/types';
import { RootState } from '../../../../state/store';

const DetailsHeader = () => {
  const dispatch = useDispatch();
  const selectedDeploymentStatus = useSelector(
    (state: RootState) => state.fleetView.selectedDeploymentStatus
  );
  const devices = useSelector((state: RootState) => state.fleetView.devices);

  const handleSearch = (value: string) => {
    if (value === '') {
      dispatch(setSearchQuery(undefined));
    } else {
      dispatch(setSearchQuery(value));
    }
  };

  const statusOptions: FilterOption<DeviceDeploymentStatus>[] = [
    {
      value: DeviceDeploymentStatus.DEPLOYED,
      label: 'Deployed',
      badgeStatus: 'blue',
    },
    {
      value: DeviceDeploymentStatus.READY,
      label: 'Ready',
      badgeStatus: 'green',
    },
    {
      value: DeviceDeploymentStatus.ON_STANDBY,
      label: 'On Standby',
      badgeStatus: 'gray',
    },
  ];

  const handleStatusChange = (value: DeviceDeploymentStatus | 'all') => {
    dispatch(setSelectedDeploymentStatus(value === 'all' ? null : value));
  };

  return (
    <div className={styles.detailsHeader}>
      <FilterToggleGroup
        items={devices}
        options={statusOptions}
        selectedValue={selectedDeploymentStatus || 'all'}
        filterKey="deviceDeploymentStatus"
        onValueChange={handleStatusChange}
      />
      <div className={styles.filterRow}>
        <TextInput
          variant="filter"
          placeholder={'Search'}
          iconLeft={IconSearch}
          onChange={handleSearch}
          className={styles.searchInput}
        />
        <FleetViewFilter />
        <FleetViewSort />
      </div>
    </div>
  );
};

export default DetailsHeader;

import React from 'react';
import { Label } from '../../../../aurora/typography/Label/Label';
import { Text } from '../../../../aurora/typography/Text/Text';
import { SegmentEntity } from '../../../../state/segments/types';
import { SegmentSnapshotEntity } from '../../../../state/segmentSnapshots/types';
import { formatDateTime, secondsToDuration } from '../../../../utils/dateUtil';
import { getSnapshotInternalTemp } from '../../../../utils/segmentSnapshot/data';
import styles from './CoolingSummary.module.scss';
import { calculateSteadyStateTemps } from '../../../../utils/temp/tempUtil';
import { calculateMeanKineticTemp } from '../../../../utils/temp/calculateMeanKineticTemp';

interface Props {
  segment: SegmentEntity;
  snapshots: SegmentSnapshotEntity[];
  tempThresholdsC: [number, number];
}

// TODO: include shipping profile info?
const CoolingSummary: React.FC<Props> = ({
  segment,
  snapshots,
  tempThresholdsC,
}) => {
  const internalTemps = snapshots.map((snapshot) =>
    getSnapshotInternalTemp(snapshot, segment.deviceType)
  );
  const { min: minTemp, max: maxTemp } = calculateSteadyStateTemps(
    internalTemps,
    tempThresholdsC[0],
    tempThresholdsC[1]
  );
  const meanKineticTemp = calculateMeanKineticTemp(
    snapshots,
    segment.deviceType
  );

  return (
    <div className={styles.summary}>
      <div className={styles.row}>
        <Label span size="sm" color="strong900">
          Cooling Start:&nbsp;
        </Label>
        <Text span size="sm" color="sub600">
          {formatDateTime(segment.startTime)}
        </Text>
      </div>
      <div className={styles.row}>
        <Label span size="sm" color="strong900">
          Cooling End:&nbsp;
        </Label>
        <Text span size="sm" color="sub600">
          {formatDateTime(segment.endTime)}
        </Text>
      </div>
      <div className={styles.row}>
        <Label span size="sm" color="strong900">
          Total Cooling Time:&nbsp;
        </Label>
        <Text span size="sm" color="sub600">
          {secondsToDuration(segment.lengthSec)}
        </Text>
      </div>
      {minTemp !== null && (
        <div className={styles.row}>
          <Label span size="sm" color="strong900">
            Minimum Temperature Reached:&nbsp;
          </Label>
          <Text span size="sm" color="sub600">
            {minTemp}°C
          </Text>
        </div>
      )}
      {maxTemp !== null && (
        <div className={styles.row}>
          <Label span size="sm" color="strong900">
            Maximum Temperature Reached:&nbsp;
          </Label>
          <Text span size="sm" color="sub600">
            {maxTemp}°C
          </Text>
        </div>
      )}
      {meanKineticTemp !== undefined && (
        <div className={styles.row}>
          <Label span size="sm" color="strong900">
            Mean Kinetic Temperature:&nbsp;
          </Label>
          <Text span size="sm" color="sub600">
            {meanKineticTemp.toFixed(2)}°C
          </Text>
        </div>
      )}
    </div>
  );
};

export default CoolingSummary;

import React, { useEffect, useState } from 'react';
import { Text } from '../../../aurora/typography/Text/Text';
import { Button } from '../../../aurora/components/Button/legacy/Button';
import MultiSelectCheckboxList from './MultiSelectCheckboxList';

interface Props<T> {
  onSubmit: (entityTypes: T[]) => void;
  onClear: () => void;
  onCancel: () => void;
  entityTypes: T[];
  displayEntityTypes: string[];
  filteredEntityTypes: T[];
}

const MultiSelectPopover = <T extends string>({
  onSubmit,
  onClear,
  onCancel,
  entityTypes,
  displayEntityTypes,
  filteredEntityTypes,
}: Props<T>) => {
  const [selectedEntityTypes, setSelectedEntityTypes] =
    useState<T[]>(filteredEntityTypes);

  useEffect(() => {
    setSelectedEntityTypes(filteredEntityTypes);
  }, [filteredEntityTypes]);

  const onApply = () => {
    if (selectedEntityTypes.length === 0) {
      onClear();
    } else {
      onSubmit(selectedEntityTypes);
    }
  };

  return (
    <div style={{ minWidth: '300px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text size="sm" sx={{ fontWeight: '500' }}>
          Entity Types
        </Text>
        <Button variant="ghostPrimary" label="Clear all" onClick={onClear} />
      </div>
      <MultiSelectCheckboxList
        selectedOptions={selectedEntityTypes}
        options={entityTypes}
        displayOptions={displayEntityTypes}
        onChange={(entityTypes) => setSelectedEntityTypes(entityTypes)}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '16px',
          gap: '12px',
        }}
      >
        <Button variant="secondary" label="Cancel" onClick={onCancel} />
        <Button variant="primary" label="Apply" onClick={onApply} />
      </div>
    </div>
  );
};

export default MultiSelectPopover;

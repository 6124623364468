import React from 'react';
import { Tooltip } from '../../../../aurora/components/Tooltip/Tooltip';
import { IconCircleInfo } from '../../../../aurora/icons';
import styles from './ShippingProfileForm.module.scss';

interface ShippingProfileFormSectionProps {
  title: string;
  children: React.ReactNode;
  tooltip?: string;
  firstSection?: boolean;
}

const ShippingProfileFormSection: React.FC<ShippingProfileFormSectionProps> = ({
  title,
  children,
  tooltip,
  firstSection,
}) => {
  return (
    <div className={`${styles.section} ${firstSection ? 'first-child' : ''}`}>
      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>
          {title}
          {tooltip && (
            <Tooltip label={tooltip}>
              <IconCircleInfo color="gray50v1" height={15} width={15} />
            </Tooltip>
          )}
        </span>
      </div>
      {children}
    </div>
  );
};

export default ShippingProfileFormSection;

import React from 'react';
import { Link } from 'react-router-dom';
import { IconLogo, IconLogoMark, IconHamburger } from '../../../aurora/icons';
import styles from './NavBarHeader.module.scss';

interface NavBarHeaderProps {
  toggleSlideout?: () => void;
  isCollapsed?: boolean;
  isNarrow?: boolean;
  isTransitioning?: boolean;
}

const NavBarHeader = ({
  toggleSlideout,
  isCollapsed,
  isNarrow,
  isTransitioning,
}: NavBarHeaderProps) => {
  return (
    <div className={styles.navBarHeader}>
      <Link
        className={`${styles.logoLink} 
    ${isCollapsed ? styles.collapsed : ''}
    ${isTransitioning ? styles.withTransition : ''}`}
        to="/portal/home"
      >
        {isNarrow ? (
          <IconLogoMark width={36} height={36} color={'primary600'} />
        ) : (
          <IconLogo width={142} height={36} color={'primary600'} />
        )}
      </Link>
      <div onClick={toggleSlideout} className={styles.slideoutToggle}>
        <IconHamburger width={24} height={24} />
      </div>
    </div>
  );
};

export default NavBarHeader;

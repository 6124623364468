import React, { ReactNode } from 'react';
import { formatDate, formatDateTime } from '../../../../utils/dateUtil';
import { Text } from '../../../../aurora/typography/Text/Text';
import { JourneyEntity } from '../../../../state/journeys/types';
import { OrderEntity } from '../../../../state/orders/types';
import { Label } from '../../../../aurora/typography/Label/Label';
import styles from './OrderInfoSlideoutContent.module.scss';
import OrderStatusBadge from './OrderStatusBadge';
import { DeviceType } from '../../../../state/devices/types';
import { RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import { ShippingProfileEntity } from '../../../../state/shippingProfile/types';
import { CompanyEntity } from '../../../../state/companies/types';

interface InfoPairProps {
  label: string;
  value?: string | ReactNode;
  artycOnly?: boolean;
}
const InfoPair = ({ label, value }: InfoPairProps) => {
  if (value === undefined || value === null) {
    return null;
  }

  return (
    <div>
      <Label size="sm" color="strong900" span>
        {label}:
      </Label>
      <Text size="sm" color="sub600" span>
        {' '}
        {value}
      </Text>
    </div>
  );
};

interface SectionProps {
  title?: string;
  pairs: InfoPairProps[];
  isArtycViewer: boolean;
}
const Section = ({ title, pairs, isArtycViewer }: SectionProps) => {
  if (pairs.length === 0) {
    return null;
  }

  return (
    <div className={styles.section}>
      {title && (
        <div className={styles.sectionTitle}>
          <Label size="sm">{title}</Label>
        </div>
      )}
      <div className={styles.sectionContent}>
        {pairs
          .filter(
            (pair) => !pair.artycOnly || (pair.artycOnly && isArtycViewer)
          )
          .map((pair, idx) => (
            <InfoPair key={idx} {...pair} />
          ))}
      </div>
    </div>
  );
};

interface Props {
  isArtycViewer: boolean;
  journey: JourneyEntity;
  order: OrderEntity;
  company: CompanyEntity;
  shippingProfile: ShippingProfileEntity | undefined;
}

const OrderInfoSlideoutContent = ({
  isArtycViewer,
  journey,
  order,
  company,
  shippingProfile,
}: Props) => {
  const shipments = useSelector(
    (root: RootState) => root.journeys.shipmentsByJourney[journey._id]
  );

  const header: InfoPairProps[] = [
    { label: 'Order Internal ID', value: order._id, artycOnly: true },
    { label: 'Order Public ID', value: order.pid },
    { label: 'Status', value: <OrderStatusBadge status={order.status} /> },
    { label: 'Submission Date', value: formatDateTime(order.createdAt) },
    {
      label: 'Fulfillment Date',
      value: order.fulfilledAt ? formatDateTime(order.fulfilledAt) : undefined,
    },
  ];

  // for now assume there's only up to one custom kitting
  const customKitting =
    company.kittings !== undefined && company.kittings.length > 0
      ? company.kittings[0].name
      : undefined;
  const orderDetails: InfoPairProps[] = [
    { label: 'PO Number', value: order.poNumber || '-' },
    { label: 'Custom ID', value: order.customId || '-' },
    { label: 'Company', value: order.companyName, artycOnly: true },
    { label: 'Custom Kitting', value: customKitting, artycOnly: true },
    // for now these are hardcoded until we support more quantities / other shipper types
    // TODO(order): support other shipper types
    { label: 'Shipper', value: DeviceType.MedstowMicro, artycOnly: true },
    { label: 'Quantity', value: 1, artycOnly: true },
    { label: 'Shipping Profile', value: shippingProfile?.name },
    {
      label: 'Delivery Date',
      value: order.expectedDeliveryDate
        ? formatDate(order.expectedDeliveryDate)
        : '-',
    },
  ];

  const userAddress = (shipments || []).find(
    (shipment) => shipment.leg === 1
  )?.destination;
  const userAddressInfo: InfoPairProps[] = userAddress
    ? [
        { label: 'Address Line 1', value: userAddress.addressLine1 },
        { label: 'Address Line 2', value: userAddress.addressLine2 },
        { label: 'City', value: userAddress.city },
        { label: 'State', value: userAddress.state },
        { label: 'Postal Code', value: userAddress.postalCode },
      ]
    : [];

  const labAddress = (shipments || []).find(
    (shipment) => shipment.leg === 2
  )?.destination;
  const labAddressInfo: InfoPairProps[] = labAddress
    ? [
        { label: 'Lab Name', value: labAddress.addressName },
        { label: 'ATTN', value: labAddress.attn },
        { label: 'Address Line 1', value: labAddress.addressLine1 },
        { label: 'Address Line 2', value: labAddress.addressLine2 },
        { label: 'City', value: labAddress.city },
        { label: 'State', value: labAddress.state },
        { label: 'Postal Code', value: labAddress.postalCode },
      ]
    : [];

  const activityLog: InfoPairProps[] = [
    { label: 'Submitted By', value: order.placedByUserName },
    { label: 'Last Modified', value: formatDateTime(order.updatedAt) },
  ];

  return (
    <>
      <Section pairs={header} isArtycViewer={isArtycViewer} />
      <Section
        title="Order Details"
        pairs={orderDetails}
        isArtycViewer={isArtycViewer}
      />
      <Section
        title="Leg 1: User Address"
        pairs={userAddressInfo}
        isArtycViewer={isArtycViewer}
      />
      <Section
        title="Leg 2: Lab Address"
        pairs={labAddressInfo}
        isArtycViewer={isArtycViewer}
      />
      <Section
        title="Activity Log"
        pairs={activityLog}
        isArtycViewer={isArtycViewer}
      />
    </>
  );
};

export default OrderInfoSlideoutContent;

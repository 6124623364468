import React, { useMemo, useRef, useState, useEffect } from 'react';
import Map, { MapPointGroup } from '../../../../components/map/Map';
import styles from '../../FleetViewPage.module.scss';
import { FleetViewPointProperties, mkPointGroups } from './mkPointGroups';
import mapboxgl from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSerialNumber } from '../../../../state/fleetView/';
import { FleetViewDevice } from '../../../../state/fleetView/types';
import { RootState } from '../../../../state/store';

interface FleetViewMapProps {
  mappedDevices: FleetViewDevice[];
}

const FleetViewMap = ({ mappedDevices }: FleetViewMapProps) => {
  const dispatch = useDispatch();
  const selectedSerialNumber = useSelector(
    (state: RootState) => state.fleetView.selectedSerialNumber
  );

  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [zoom, setZoom] = useState<number>(2);

  useEffect(() => {
    if (map && selectedSerialNumber !== null) {
      const selectedDevice = mappedDevices.find(
        (device) => device.serialNumber === selectedSerialNumber
      );

      if (selectedDevice?.lastKnownLocation) {
        const { lat, long } = selectedDevice.lastKnownLocation;

        map.flyTo({
          center: [long, lat],
          zoom: Math.max(12, map.getZoom()),
        });
      }
    }
  }, [mappedDevices, map, selectedSerialNumber]);

  useEffect(() => {
    if (map) {
      // we want to update the clustering when the zoom level changes
      map.on('zoom', () => {
        setZoom(map.getZoom());
      });
    }
  }, [map]);

  const onClick = (properties: FleetViewPointProperties) => {
    if (properties?.serialNumber !== undefined) {
      dispatch(setSelectedSerialNumber(properties.serialNumber));
    }
  };

  const pointGroups: MapPointGroup[] = useMemo(() => {
    return mkPointGroups(mappedDevices, map, onClick);
  }, [mappedDevices, map, zoom]);

  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (map) {
        map.resize();
      }
    };

    const container = mapContainerRef.current;
    if (container) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(container);

      // Cleanup on component unmount
      return () => {
        resizeObserver.unobserve(container);
        resizeObserver.disconnect();
      };
    }
  }, [map]);

  return (
    <Map
      pointGroups={pointGroups}
      ref={mapContainerRef}
      className={styles.customMap}
      onMapLoad={setMap}
    />
  );
};

export default FleetViewMap;

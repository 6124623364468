import { SelectOptionType } from '../../../../aurora/components/Select/Select';
import { DeviceType } from '../../../../state/devices/types';

export const getIntervalOptions = (
  shipperType: DeviceType
): SelectOptionType[] => {
  if (shipperType === DeviceType.MedstowMicro) {
    return [
      { value: '300', label: '5 minutes' },
      { value: '600', label: '10 minutes' },
      { value: '1200', label: '20 minutes' },
      { value: '1800', label: '30 minutes' },
      { value: '3600', label: '60 minutes' },
      { value: '7200', label: '120 minutes' },
      { value: '14400', label: '240 minutes' },
    ];
  } else if (shipperType === DeviceType.Medstow5LI) {
    return [
      { value: '60', label: '1 minute' },
      { value: '120', label: '2 minutes' },
      { value: '300', label: '5 minutes' },
      { value: '600', label: '10 minutes' },
      { value: '1200', label: '20 minutes' },
      { value: '1800', label: '30 minutes' },
      { value: '3600', label: '60 minutes' },
    ];
  }

  return [];
};

export const steadyStateWindowOptions: SelectOptionType[] = [
  { value: '1200', label: '20 minutes' },
  { value: '1800', label: '30 minutes' },
  { value: '2700', label: '45 minutes' },
  { value: '3600', label: '60 minutes' },
  { value: '5400', label: '90 minutes' },
];

export const cooldownWindowOptions: SelectOptionType[] = [
  { value: '3600', label: '60 minutes' },
  { value: '5400', label: '90 minutes' },
  { value: '7200', label: '120 minutes' },
  { value: '9000', label: '150 minutes' },
  { value: '10800', label: '180 minutes' },
];

export const shipperTypeOptions: SelectOptionType[] = [
  { value: DeviceType.Medstow5LI, label: 'Medstow 5L-I' },
  { value: DeviceType.MedstowMicro, label: 'Medstow Micro' },
];

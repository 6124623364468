import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../aurora/components/Button/Button';
import { IconPlusCircleFill } from '../../../../aurora/icons';
import {
  setAddressTypeFilter,
  setCompanyId,
  setCurrentPage,
  setSearchFilter,
  resetAddressState,
  resetPage,
} from '../../../../state/addresses';
import { isArtyc } from '../../../../state/auth';
import { selectAuth } from '../../../../state/auth';
import { RootState } from '../../../../state/store';
import DataTableContainer from '../../../../components/table/DataTableContainer';
import {
  AddressEntity,
  AddressTypeEnum,
} from '../../../../state/addresses/types';
import styles from '../../../page.module.scss';
import { loadAddresses, sortAddresses } from '../../../../state/addresses';
import { ColumnsConfig } from '../../../../components/table/tableConfig';
import MultiSelectFilter from '../../../../components/filters/MultiSelectFilter/MultiSelectFilter';
import TableSearch from '../../../../aurora/components/TableSearch/TableSearch';
import { capitalize } from '../../../../utils/stringUtil';
import CompanySelect from '../../../../components/filters/CompanySelect/CompanySelect';
import ActionMenu from '../../../../components/table/ActionMenu';
import AddressFormSlideout from './AddressFormSlideout';
import { Tooltip } from '../../../../aurora/components/Tooltip/Tooltip';
import { StatusModal } from '../../../../aurora/components/StatusModal/StatusModal';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import AddressesApi from '../../../../api/addressesApi';
import { showToast } from '../../../../aurora/components/Toast/Toast';
import { AxiosInstance } from 'axios';
import { Auth } from '../../../../state/auth/types';
import { EntityState } from '../../../../state/types';
import { setAddresses } from '../../../../state/addresses';

interface AddButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const AddButton = ({ onClick, disabled }: AddButtonProps) => {
  const button = (
    <Button
      label="Add Address"
      onClick={onClick}
      style="filled"
      type="primary"
      size="md"
      iconLeft={IconPlusCircleFill}
      disabled={disabled}
    />
  );

  if (disabled) {
    return <Tooltip label="Please select a company first">{button}</Tooltip>;
  }

  return button;
};

const AddressSection = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const axios = useAxiosPrivate();
  const addressesState = useSelector((state: RootState) => state.addresses);

  const [showAddressForm, setShowAddressForm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<
    AddressEntity | undefined
  >();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const addressTypeOptions = Object.values(AddressTypeEnum).filter(
    (type) => type !== AddressTypeEnum.USER
  );

  const { addressTypes } = useSelector(
    (state: RootState) => state.addresses.selectedFilters || {}
  );
  const companyId = useSelector(
    (state: RootState) => state.addresses.companyId
  );

  useEffect(() => {
    return () => {
      dispatch(resetAddressState());
    };
  }, [dispatch]);

  const displayOptions = addressTypeOptions.map(capitalize);

  const isCompanySelected = companyId !== undefined;
  const showCustomNotice = isArtyc(auth) && !isCompanySelected;

  const customNotice = showCustomNotice
    ? 'Select a company from the dropdown to view and manage its addresses'
    : undefined;

  useEffect(() => {
    if (auth && !isArtyc(auth)) {
      // For non-Artyc users, always use their company from auth
      dispatch(setCompanyId(auth.companyId));
    }
  }, [auth, dispatch]);

  const handleCloseSlideout = () => {
    setShowAddressForm(false);
    setSelectedAddress(undefined);
    if (isCompanySelected) {
      dispatch(resetPage());
    }
  };

  const handleEditAddress = (address: AddressEntity) => {
    setSelectedAddress(address);
    setShowAddressForm(true);
  };

  const handleAddClick = () => {
    setSelectedAddress(undefined);
    setShowAddressForm(true);
  };

  const handleDeleteClick = (address: AddressEntity) => {
    setSelectedAddress(address);
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = async (address: AddressEntity) => {
    try {
      if (auth === null || companyId === undefined) {
        showToast({
          type: 'error',
          title: 'Error',
          text: !auth
            ? 'You must be logged in to delete addresses'
            : 'Please select a company before deleting addresses',
        });
        return;
      }

      await AddressesApi.deleteAddress(axios, auth, address._id, companyId);

      showToast({
        type: 'success',
        title: 'Success',
        text: 'Address deleted successfully',
      });

      // Refresh the data
      dispatch(await loadAddresses(axios, auth, addressesState));
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'Failed to delete address. Please try again.',
      });
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false);
  };

  const topConfig = {
    additionalBars: [
      <>
        <div className={styles.filterGroup}>
          {isArtyc(auth) && (
            <CompanySelect
              onSetCompanyId={(companyId) => {
                dispatch(resetPage());
                dispatch(setCompanyId(companyId));
              }}
              selectedCompanyId={companyId}
              nullable
            />
          )}
          <MultiSelectFilter
            options={addressTypeOptions}
            displayOptions={displayOptions}
            selectedOptions={addressTypes}
            setOptionsAction={(addressTypes: AddressTypeEnum[] = []) =>
              dispatch(setAddressTypeFilter(addressTypes))
            }
            placeholder="Select Address Type"
          />
          <TableSearch
            placeholder="Search..."
            onChange={(text) => {
              dispatch(setSearchFilter(text));
            }}
          />
        </div>
        <div>
          <AddButton onClick={handleAddClick} disabled={!isCompanySelected} />
        </div>
      </>,
    ],
  };

  const columnsConfig: ColumnsConfig<AddressEntity> = {
    columns: [
      { title: 'Name/Label', property: 'addressName' },
      { title: 'Building/Dept.', property: 'addressLine2' },
      { title: 'Attn.', property: 'attn' },
      { title: 'Address', property: 'addressLine1' },
      { title: 'City', property: 'city' },
      { title: 'State', property: 'state' },
      { title: 'Postal Code', property: 'postalCode' },
      {
        title: 'Type',
        property: 'addressType',
        customData: (entity) => capitalize(entity.addressType),
      },
    ],
    actionColumn: (entity) => (
      <ActionMenu<AddressEntity>
        entity={entity}
        actions={[
          {
            label: 'Edit',
            onClick: handleEditAddress,
          },
          {
            label: 'Delete',
            onClick: handleDeleteClick,
          },
        ]}
      />
    ),
  };

  const emptyConfig = {
    icon: IconPlusCircleFill,
    title: 'No addresses found',
    body: "Once addresses are added, they'll show up here",
  };

  const loadDataActionCallback = useCallback(
    (axios: AxiosInstance, auth: Auth, state: EntityState<AddressEntity>) => {
      if (isCompanySelected) {
        return loadAddresses(axios, auth, state);
      }
      return Promise.resolve(setAddresses({ entities: [], totalPages: 0 }));
    },
    [isCompanySelected, companyId]
  );

  return (
    <>
      <DataTableContainer
        topConfig={topConfig}
        columnsConfig={columnsConfig}
        sortAction={sortAddresses}
        loadDataAction={loadDataActionCallback}
        pageAction={setCurrentPage}
        resetAction={resetPage}
        stateName="addresses"
        emptyConfig={emptyConfig}
        customNotice={customNotice}
      />
      <AddressFormSlideout
        open={showAddressForm}
        closeSlideout={handleCloseSlideout}
        companyId={companyId}
        initialAddress={selectedAddress}
        onDelete={handleDeleteClick}
      />
      <StatusModal
        type="error"
        title="Are you sure you want to delete this address?"
        description="Deleting this address will permanently remove it from your list. This action cannot be undone."
        show={showDeleteConfirm}
        showIcon={false}
        matchButtonType={true}
        onClose={handleDeleteCancel}
        primaryButton={{
          label: 'Delete',
          onClick: () => {
            if (selectedAddress) {
              handleDeleteConfirm(selectedAddress);
              setShowAddressForm(false);
              setSelectedAddress(undefined);
            }
          },
        }}
        secondaryButton={{
          label: 'Cancel',
          onClick: handleDeleteCancel,
        }}
      />
    </>
  );
};

export default AddressSection;

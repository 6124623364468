import { SegmentEntity } from '../../state/segments/types';
import { ShippingProfileEntity } from '../../state/shippingProfile/types';

export const cToF = (celsius: number) => {
  return (celsius * 9) / 5 + 32;
};

export const fToC = (fahrenheit: number) => {
  return ((fahrenheit - 32) * 5) / 9;
};

export const toDecimalPlaces = (num: number, decimalPlaces: number) => {
  return parseFloat(num.toFixed(decimalPlaces));
};

// min/max temps that we reached while in steady state
export const calculateSteadyStateTemps = (
  temps: number[],
  steadyStateMinTemp: number,
  steadyStateMaxTemp: number
): { min: number | null; max: number | null } => {
  if (temps.length === 0) return { min: null, max: null };

  // start considering min/max from when we first reached the temp range
  const steadyStateStartIndex = temps.findIndex(
    (t) => t >= steadyStateMinTemp && t <= steadyStateMaxTemp
  );
  if (steadyStateStartIndex === -1) {
    return { min: null, max: null };
  }

  const steadyStateTemps = temps.slice(steadyStateStartIndex);
  return {
    min: Math.min(...steadyStateTemps),
    max: Math.max(...steadyStateTemps),
  };
};

export const calculateTempThresholds = (
  shippingProfile: ShippingProfileEntity | undefined,
  segment: SegmentEntity
): [number, number] => {
  if (shippingProfile !== undefined) {
    return [
      shippingProfile.minSteadyStateTempC,
      shippingProfile.maxSteadyStateTempC,
    ];
  }

  // legacy default
  return [segment.setTemperature - 3, segment.setTemperature + 3];
};

import React, { useEffect, useState } from 'react';
import {
  ColumnsConfig,
  SlideoutConfig,
  EmptyConfig,
  PaginationConfig,
  SortConfig,
  TopConfig,
} from './tableConfig';
import DataTable from './DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { useEntityState } from '../../state/store';
import {
  EntityStateExtension,
  LoadDataAction,
  PageAction,
  ResetAction,
  SortAction,
} from '../../state/types';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { selectAuth } from '../../state/auth';

interface Props<T> {
  stateName: EntityStateExtension<T>;
  loadDataAction: LoadDataAction<T> | null;
  sortAction: SortAction<T>;
  pageAction: PageAction;
  topConfig: TopConfig;
  emptyConfig: EmptyConfig;
  columnsConfig: ColumnsConfig<T>;
  slideoutConfig?: SlideoutConfig<T>;
  resetAction?: ResetAction;
  customNotice?: string;
}

// a table that "just works" for EntityState data
const DataTableContainer = <T extends object>({
  topConfig,
  emptyConfig,
  columnsConfig,
  slideoutConfig,
  stateName,
  loadDataAction,
  sortAction,
  pageAction,
  resetAction,
  customNotice,
}: Props<T>) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const state = useEntityState(stateName);

  const {
    data,
    currentPage,
    totalPages,
    resultsPerPage,
    sortColumn,
    sortDirection,
    selectedFilters,
  } = state;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    if (loadDataAction !== null) {
      const loadData = async () => {
        try {
          setError(undefined);
          setLoading(true);
          const action = await loadDataAction(axiosPrivate, auth!, state);
          dispatch(action);
        } catch (e: any) {
          setError(e);
        } finally {
          setLoading(false);
        }
      };
      loadData();
    }
  }, [
    currentPage,
    resultsPerPage,
    sortColumn,
    sortDirection,
    selectedFilters,
    loadDataAction,
  ]);

  // cleanup on dismount
  useEffect(
    () => () => {
      if (resetAction !== undefined) {
        dispatch(resetAction());
      }
    },
    []
  );

  const onSortByColumn = (columnKey: keyof T) => {
    const isCurrentColumn = columnKey === sortColumn;
    const direction = isCurrentColumn ? sortDirection * -1 : -1;
    dispatch(sortAction({ column: columnKey, direction }));
  };
  const sortConfig: SortConfig<T> = {
    sortColumn: sortColumn as keyof T,
    sortDirection,
    onSortByColumn,
  };

  const onPage = (page: number) => {
    dispatch(pageAction(page));
  };
  const paginationConfig: PaginationConfig = {
    currentPage,
    totalPages,
    onPage,
  };

  return (
    <DataTable
      topConfig={topConfig}
      emptyConfig={emptyConfig}
      columnsConfig={columnsConfig}
      sortConfig={sortConfig}
      paginationConfig={paginationConfig}
      slideoutConfig={slideoutConfig}
      data={data as T[]}
      loading={loading}
      error={error}
      customNotice={customNotice}
    />
  );
};

export default DataTableContainer;

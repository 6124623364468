import { OptionalField, EntityState } from '../types';

export enum AddressTypeEnum {
  WAREHOUSE = 'WAREHOUSE',
  USER = 'USER',
  LAB = 'LAB',
  CAMPUS = 'CAMPUS',
  OTHER = 'OTHER',
}

export interface AddressEntity {
  _id: string;
  addressName: OptionalField<string>;
  attn: OptionalField<string>;
  addressLine1: string;
  addressLine2: OptionalField<string>;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  addressType: AddressTypeEnum;
  phoneNumber: OptionalField<string>;
  latitude: OptionalField<number>;
  longitude: OptionalField<number>;
  buildingDepartment: OptionalField<string>;
  suiteUnitDepartment: OptionalField<string>;
}

export type NewAddressEntity = Omit<
  AddressEntity,
  '_id' | 'latitude' | 'longitude'
>;
export type NewOrExistingAddressEntity =
  | NewAddressEntity
  | Pick<AddressEntity, '_id'>;

export interface AddressFilters {
  search?: string;
  addressTypes?: AddressTypeEnum[];
}

export interface AddressState extends EntityState<AddressEntity> {
  selectedFilters: AddressFilters;
  companyId: string | undefined;
}

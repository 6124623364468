import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconMoreLine: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase width={25} height={24} viewBox="0 0 25 24" {...props}>
    <Path
      d="M5.75002 10.6499C5.00752 10.6499 4.40002 11.2574 4.40002 11.9999C4.40002 12.7424 5.00752 13.3499 5.75002 13.3499C6.49252 13.3499 7.10002 12.7424 7.10002 11.9999C7.10002 11.2574 6.49252 10.6499 5.75002 10.6499ZM19.25 10.6499C18.5075 10.6499 17.9 11.2574 17.9 11.9999C17.9 12.7424 18.5075 13.3499 19.25 13.3499C19.9925 13.3499 20.6 12.7424 20.6 11.9999C20.6 11.2574 19.9925 10.6499 19.25 10.6499ZM12.5 10.6499C11.7575 10.6499 11.15 11.2574 11.15 11.9999C11.15 12.7424 11.7575 13.3499 12.5 13.3499C13.2425 13.3499 13.85 12.7424 13.85 11.9999C13.85 11.2574 13.2425 10.6499 12.5 10.6499Z"
      fill="currentColor"
    />
  </IconBase>
);

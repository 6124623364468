import React, { useEffect, useState } from 'react';
import DevicesApi from '../../../../api/devicesApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import JourneysApi from '../../../../api/journeysApi';
import { ShipmentEntity } from '../../../../state/shipments/types';
import { showToast } from '../../../../aurora/components/Toast/Toast';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import styles from './FulfillOrderFormModal.module.scss';
import LoadingSpinner from '../../../../components/layout/LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../state/auth';
import { DeviceEntity, DeviceType } from '../../../../state/devices/types';
import { FulfillOrderFormData, orderToFulfillFormData } from './mapOrderData';
import { Form } from 'react-final-form';
import FulfillOrderForm from './FulfillOrderForm';
import arrayMutators from 'final-form-arrays';
import { OrderEntity } from '../../../../state/orders/types';
import { JourneyEntity } from '../../../../state/journeys/types';

interface Props {
  order: OrderEntity;
  isArtycViewer: boolean;
  onSubmit: (data: FulfillOrderFormData) => Promise<any>;
  onClose: () => void;
  // TODO(order): can remove this prop when we switch EditOrderButton to CaasOrderForm
  fulfilling: boolean;
}

const FulfillOrderFormModal = ({
  order,
  isArtycViewer,
  onSubmit,
  onClose,
  fulfilling,
}: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [journey, setJourney] = useState<JourneyEntity | null>(null);
  const [shipments, setShipments] = useState<ShipmentEntity[]>([]);
  const [shippers, setShippers] = useState<DeviceEntity[]>([]);
  const shipperOptions = shippers.map((shipper) => ({
    value: shipper._id,
    label: shipper.serialNumber,
  }));

  useEffect(() => {
    const fetchData = async () => {
      if (order.journeyId) {
        const journeyResp = await JourneysApi.getJourney(
          axios,
          auth,
          order.journeyId
        );
        setJourney(journeyResp);

        const shipmentsResp = await JourneysApi.getShipmentsForJourney(
          axios,
          auth,
          journeyResp._id
        );
        setShipments(shipmentsResp);
      }

      if (isArtycViewer) {
        const shippersResp = await DevicesApi.getArtycDevices(
          axios,
          auth,
          DeviceType.MedstowMicro
        );
        setShippers(shippersResp.entities);
      }

      setHasFetchedData(true);
    };

    fetchData();
  }, []);

  const submit = async (data: FulfillOrderFormData) => {
    setIsSubmitting(true);

    try {
      await onSubmit(data);
      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Your changes have been saved successfully.',
      });
      onClose();
    } catch (err: any) {
      showToast({
        type: 'error',
        title: 'Error',
        text:
          err.response?.data?.message ||
          'Something went wrong. Please try again.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!hasFetchedData) {
    return (
      <div className={`${styles.formContainer} ${styles.spinner}`}>
        <LoadingSpinner width={50} height={50} />
      </div>
    );
  }

  // TODO(order): fix for lease orders, which won't have journey
  if (!journey) {
    return (
      <div className={styles.modalContainer}>
        <div>No journey found for order</div>
      </div>
    );
  }

  return (
    <Form
      onSubmit={submit}
      mutators={{ ...arrayMutators }}
      initialValues={orderToFulfillFormData(order, journey, shipments)}
      render={({ handleSubmit }) => (
        <div className={styles.modalContainer}>
          <div className={styles.scrollableContent}>
            <FulfillOrderForm
              isArtycViewer={isArtycViewer}
              shipperOptions={shipperOptions}
              fulfilling={fulfilling}
            />
          </div>
          <div className={styles.stickyFooter}>
            <Button
              variant="secondary"
              label="Cancel"
              onClick={onClose}
              disabled={isSubmitting}
            />
            <Button
              variant="primary"
              label="Save"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </div>
      )}
    />
  );
};

export default FulfillOrderFormModal;

import { AxiosInstance } from 'axios';

interface MfaVerifyRequest {
  userId: string;
  code: string;
  stayLoggedIn: boolean;
}

interface MfaResendRequest {
  userId: string;
  stayLoggedIn: boolean;
}

interface MfaVerifyResponse {
  accessToken: string;
}

const AuthApi = {
  verifyMfa: async (
    axios: AxiosInstance,
    request: MfaVerifyRequest
  ): Promise<MfaVerifyResponse> => {
    const response = await axios.post('/auth/mfa', request);
    return response.data;
  },

  resendMfa: async (
    axios: AxiosInstance,
    request: MfaResendRequest
  ): Promise<void> => {
    await axios.post('/auth/mfa/resend', request);
  },
};

export default AuthApi;

import React, { ComponentType, useCallback } from 'react';
import { Button, ButtonType, ButtonStyle, ButtonSize } from '../Button/Button';
import { IconProps } from '../../icons/IconBase';
import {
  IconCheckCircleFill as IconSuccess,
  IconWarningCircleFill as IconError,
  IconWarningCircleFill as IconInfo,
  IconWarningFill as IconWarning,
} from '../../icons';
import styles from './StatusModal.module.scss';

// TODO(StatusModal):
// - Add footer types
// - Add optional header

export type StatusModalType = 'error' | 'warning' | 'information' | 'success';
export type StatusModalAlignment = 'horizontal' | 'vertical';
type StatusModalSize = (typeof ButtonSize)[keyof typeof ButtonSize];

interface ButtonConfig {
  label: string;
  onClick: () => void;
  iconLeft?: ComponentType<IconProps>;
  iconRight?: ComponentType<IconProps>;
}

interface Props {
  type: StatusModalType;
  alignment?: StatusModalAlignment;
  title: string;
  description?: string;
  showIcon?: boolean;
  primaryButton: ButtonConfig;
  secondaryButton?: ButtonConfig;
  size?: StatusModalSize;
  show: boolean;
  onClose: () => void;
  matchButtonType?: boolean;
}

// Maps modal types directly to button types
const modalToButtonType: Record<
  StatusModalType,
  (typeof ButtonType)[keyof typeof ButtonType]
> = {
  error: ButtonType.Error,
  warning: ButtonType.Warning,
  information: ButtonType.Primary,
  success: ButtonType.Success,
};

const getIconFromModalType = (
  type: StatusModalType
): ComponentType<IconProps> => {
  switch (type) {
    case 'error':
      return IconError;
    case 'warning':
      return IconWarning;
    case 'information':
      return IconInfo;
    case 'success':
      return IconSuccess;
    default:
      return IconInfo;
  }
};

export const StatusModal = ({
  type,
  alignment = 'horizontal',
  title,
  description,
  showIcon = true,
  primaryButton,
  secondaryButton,
  size = 'md',
  show,
  onClose,
  matchButtonType = false,
}: Props) => {
  const buttonType = matchButtonType
    ? modalToButtonType[type]
    : ButtonType.Primary;
  const Icon = getIconFromModalType(type);

  const handleOverlayClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  if (!show) return null;

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.statusModal}>
        <div className={`${styles.content} ${styles[alignment]}`}>
          {showIcon && (
            <div className={`${styles.iconContainer} ${styles[type]}`}>
              <Icon width={24} height={24} />
            </div>
          )}
          <div className={styles.textSection}>
            <div className={styles.title}>{title}</div>
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.actions}>
            {secondaryButton && (
              <Button
                type={ButtonType.Neutral}
                style={ButtonStyle.Ghost}
                size={size}
                label={secondaryButton.label}
                onClick={secondaryButton.onClick}
                iconLeft={secondaryButton.iconLeft}
                iconRight={secondaryButton.iconRight}
              />
            )}
            <Button
              type={buttonType}
              style={ButtonStyle.Filled}
              size={size}
              label={primaryButton.label}
              onClick={primaryButton.onClick}
              iconLeft={primaryButton.iconLeft}
              iconRight={primaryButton.iconRight}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;

import React, { useEffect, useState, useRef } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {
  IconLogo,
  IconClose,
  IconHandle,
  IconChevronLeft,
  IconChevronRight,
} from '../../../aurora/icons';
import { Tooltip } from '../../../aurora/components/Tooltip/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { isCompanyEmployee, selectAuth } from '../../../state/auth';
import EventsApi from '../../../api/eventsApi';
import { setNewEventCount } from '../../../state/events';
import Slideout from '../../../aurora/components/Slideout/Slideout';
import styles from './NavBar.module.scss';
import NavBarHeader from './NavBarHeader';
import NavBarMenu from './NavBarMenu';

const NavBar = () => {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isHandleHovered, setIsHandleHovered] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isNarrow, setIsNarrow] = useState(false);
  const navBarRef = useRef<HTMLElement>(null);

  const toggleSlideout = () => {
    setIsSlideoutOpen(!isSlideoutOpen);
  };

  const toggleCollapse = () => {
    setIsTransitioning(true);
    setIsCollapsed((prev) => !prev);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 1000);
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      if (width !== undefined && width < 220) {
        setIsNarrow(true);
      } else {
        setIsNarrow(false);
      }
    });

    if (navBarRef.current) {
      observer.observe(navBarRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // get event count
  useEffect(() => {
    const fetchEventCount = async () => {
      const eventCount = await EventsApi.getNewEventCount(axios, auth);
      dispatch(setNewEventCount(eventCount));
    };

    if (!isCompanyEmployee(auth)) {
      fetchEventCount();
    }
  }, []);

  const renderHandleIcon = () => {
    if (!isHandleHovered) {
      return <IconHandle className={isCollapsed ? styles.iconCollapsed : ''} />;
    }
    return isTransitioning ? (
      isCollapsed ? (
        <IconChevronLeft width={20} height={20} />
      ) : (
        <IconChevronRight width={20} height={20} />
      )
    ) : isCollapsed ? (
      <IconChevronRight width={20} height={20} />
    ) : (
      <IconChevronLeft width={20} height={20} />
    );
  };

  return (
    <nav
      ref={navBarRef}
      className={`${styles.navBar} ${
        isCollapsed ? styles.collapsed : styles.expanded
      }`}
    >
      <NavBarHeader
        toggleSlideout={toggleSlideout}
        isCollapsed={isCollapsed}
        isNarrow={isNarrow}
        isTransitioning={isTransitioning}
      />
      <div className={[styles.sidebar, styles.desktopNavBar].join(' ')}>
        <NavBarMenu isNarrow={isNarrow} isCollapsed={isCollapsed} />
        <div className={styles.handleWrapper}>
          <div className={styles.tooltipWrapper}>
            <Tooltip
              label={
                isTransitioning
                  ? isCollapsed
                    ? 'Collapse'
                    : 'Expand'
                  : isCollapsed
                  ? 'Expand'
                  : 'Collapse'
              }
              placement="right"
              variant="light"
              offsetDistance={1}
            >
              <div
                className={styles.handleContainer}
                onClick={toggleCollapse}
                onMouseEnter={() => setIsHandleHovered(true)}
                onMouseLeave={() => setIsHandleHovered(false)}
              >
                {renderHandleIcon()}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.mobileNavBar}>
        <Slideout
          direction="left"
          open={isSlideoutOpen}
          onClose={toggleSlideout}
          variant="nav"
        >
          <div className={styles.slideoutHeader}>
            <div className={styles.slideoutLogo}>
              <IconLogo width={142} height={36} />
            </div>
            <div
              className={styles.slideoutCloseButtonContainer}
              onClick={toggleSlideout}
            >
              <IconClose width={24} height={24} />
            </div>
          </div>
          <div className={styles.sidebar}>
            <NavBarMenu toggleSlideout={toggleSlideout} />
          </div>
        </Slideout>
      </div>
    </nav>
  );
};

export default NavBar;

import React from 'react';
import DataModal from '../../../../components/DataModal';
import { Text } from '../../../../aurora/typography/Text/Text';
import Select, {
  SelectOptionType,
} from '../../../../aurora/components/Select/Select';
import { Button } from '../../../../aurora/components/Button/Button';
import styles from './ExportJourneyButton.module.scss';

interface Props {
  show: boolean;
  onClose: () => void;
  logType: string;
  setLogType: (value: string) => void;
  exportFormat: string;
  setExportFormat: (value: string) => void;
  onExport: () => void;
  isArtycViewer: boolean;
  reportLoading: boolean;
}

const logTypeOptions: SelectOptionType[] = [
  { label: 'Full Log', value: 'full' },
  { label: 'Customer Version', value: 'customer' },
];

const exportFormatOptions: SelectOptionType[] = [
  { label: 'CSV', value: 'csv' },
  { label: 'PDF', value: 'pdf' },
];

const ExportModal = ({
  show,
  onClose,
  logType,
  setLogType,
  exportFormat,
  setExportFormat,
  onExport,
  isArtycViewer,
  reportLoading,
}: Props) => {
  return (
    <DataModal
      title="Export Shipment Data"
      show={show}
      onClose={onClose}
      width="424px"
    >
      <div>
        <Text size="xs" color="sub600" className={styles.notice}>
          The exported file will be downloaded to your computer
        </Text>

        <div className={styles.selects}>
          {isArtycViewer && (
            <Select
              label="Log Type"
              options={logTypeOptions}
              onChange={(option) => setLogType(option.value)}
              managedValue={logType}
            />
          )}
          <Select
            label="Export Format"
            options={exportFormatOptions}
            onChange={(option) => setExportFormat(option.value)}
            managedValue={exportFormat}
          />
        </div>

        <div className={styles.footer}>
          <Button
            label="Cancel"
            type="neutral"
            style="stroke"
            size="xxs"
            onClick={onClose}
          />
          <Button
            label={reportLoading ? 'Loading...' : 'Export'}
            type="primary"
            style="filled"
            size="xxs"
            onClick={onExport}
            disabled={reportLoading}
          />
        </div>
      </div>
    </DataModal>
  );
};

export default ExportModal;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  EntityState,
  DataPayload,
  LoadDataAction,
  SortPayload,
} from '../types';
import { AddressEntity, AddressTypeEnum } from './types';
import { AxiosInstance } from 'axios';
import { Auth } from '../auth/types';
import AddressesApi from '../../api/addressesApi';

export interface AddressState extends EntityState<AddressEntity> {
  companyId?: string | undefined;
}

const initialState: AddressState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'addressLine1',
  sortDirection: -1,
  selectedFilters: {},
  companyId: undefined,
};

const addressSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setAddresses: (
      state,
      { payload }: PayloadAction<DataPayload<AddressEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    resetPage: (state) => {
      state.currentPage = 0;
    },
    setCompanyId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.companyId = payload;
      state.currentPage = initialState.currentPage;
    },
    setAddressTypeFilter: (
      state,
      { payload }: PayloadAction<AddressTypeEnum[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        addressTypes: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetAddressState: (state) => {
      Object.assign(state, initialState);
    },
    sortAddresses: (
      state,
      { payload }: PayloadAction<SortPayload<AddressEntity>>
    ) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    setSearchFilter: (state, { payload }: PayloadAction<string>) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        search: payload === '' ? undefined : payload,
      });
      state.currentPage = initialState.currentPage;
    },
  },
});

export const {
  setAddresses,
  setCurrentPage,
  resetAddressState,
  setCompanyId,
  setAddressTypeFilter,
  sortAddresses,
  setSearchFilter,
  resetPage,
} = addressSlice.actions;

export default addressSlice.reducer;

export { initialState };

export const loadAddresses: LoadDataAction<AddressEntity> = async (
  axios: AxiosInstance,
  auth: Auth,
  state: AddressState
) => {
  const {
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters,
    companyId,
  } = state;

  if (!companyId) {
    return setAddresses({ entities: [], totalPages: 0 });
  }

  const { entities, totalPages } = await AddressesApi.getCompanyAddresses(
    axios,
    auth,
    companyId,
    {
      limit: resultsPerPage,
      page: currentPage,
      sortColumn,
      sortDirection,
      filters: {
        addressTypes: selectedFilters?.addressTypes,
        search: selectedFilters?.search,
      },
    }
  );

  return setAddresses({ entities, totalPages });
};

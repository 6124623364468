import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../../aurora/typography/Text/Text';
import { isArtyc, selectAuth } from '../../../state/auth';
import styles from './ShippingProfileSection.module.scss';
import ShippingProfilesContainer from './shippingProfiles/ShippingProfilesContainer';
// import {
//   ButtonGroup,
//   ButtonProps,
// } from '../../../aurora/components/ButtonGroup/ButtonGroup';
import CompanySelect from '../../../components/filters/CompanySelect/CompanySelect';
import { Label } from '../../../aurora/typography/Label/Label';

const ShippingProfileSection = () => {
  // const [selectedType, setSelectedType] = useState<
  //   'all' | 'custom' | 'standard'
  // >('all');
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    string | undefined
  >(undefined);
  const auth = useSelector(selectAuth);

  // const types: ButtonProps[] = [
  //   {
  //     label: 'All',
  //     onClick: () => setSelectedType('all'),
  //   },
  //   {
  //     label: 'Custom',
  //     onClick: () => setSelectedType('custom'),
  //   },
  //   {
  //     label: 'Standard',
  //     onClick: () => setSelectedType('standard'),
  //   },
  // ];

  return (
    <>
      <Label size="sm" color="sub600">
        Manage and customize temperature settings and measurement intervals for
        your shipments.
      </Label>
      <div className={styles.filters}>
        {/* <ButtonGroup buttonProps={types} initialActiveIdx={0} /> */}
        <CompanySelect
          onSetCompanyId={(companyId) => setSelectedCompanyId(companyId)}
          selectedCompanyId={selectedCompanyId}
        />
      </div>
      {selectedCompanyId === undefined && isArtyc(auth) ? (
        <Text size="lg" sx={{ marginTop: '24px' }}>
          Please select a company to view profiles for
        </Text>
      ) : null}
      {selectedCompanyId !== undefined && (
        <ShippingProfilesContainer
          selectedCompanyId={selectedCompanyId}
          // selectedType={selectedType}
          selectedType="all"
        />
      )}
    </>
  );
};

export default ShippingProfileSection;

import { Medstow5LISnapshotEntity } from './medstow5LI';
import { MedstowMicroSnapshotEntity } from './medstowMicro';

export type SegmentSnapshotEntity =
  | MedstowMicroSnapshotEntity
  | Medstow5LISnapshotEntity;

export enum ReportType {
  UnitStartup = 0,
  TimerLog = 1,
  UlpLog = 2,
  LidCloseLog = 3,
  LidOpenLog = 4,
  ExcursionLog = 5,
  PresleepLog = 6,
  PostsleepLog = 7,
  PrecoolStart = 8,
  PrecoolEnd = 9,
  DestinationSet = 10,
  DestinationConfirm = 11,
  EnterTempRange = 12,
  ExitTempRange = 13,
  CellOn = 14,
  CellOff = 15,
  CellTimer = 16,
  CellTask = 17,
  ShipmentEnd = 18,
  AirplaneModeOn = 19,
  AirplaneModeOff = 20,
  ShipmentStart = 21,
}

import React from 'react';
import { Form } from 'react-final-form';
import {
  ShippingProfileEntity,
  ProfileType,
} from '../../../../state/shippingProfile/types';
import TextField from '../../../../components/forms/TextField';
import SelectField from '../../../../components/forms/SelectField';
import TextArea from '../../../../aurora/components/TextArea/TextArea';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../../../state/auth';
import { RootState } from '../../../../state/store';
import NoticeModal from '../../../../components/NoticeModal/NoticeModal';
import ShippingProfileFormSection from './ShippingProfileFormSection';
import { required } from '../../../../components/forms/validators';
import styles from './ShippingProfileForm.module.scss';
import {
  getIntervalOptions,
  steadyStateWindowOptions,
  cooldownWindowOptions,
  shipperTypeOptions,
} from './ShippingProfileFormOptions';
import TextInput from '../../../../aurora/components/TextInput/TextInput';
import {
  ShippingProfileFormData,
  shippingProfileToFormData,
} from './mapShippingProfileData';

interface ShippingProfileFormProps {
  profile?: ShippingProfileEntity;
  selectedCompanyId?: string;
  onSave: (profileData: ShippingProfileFormData) => Promise<void>;
  onDelete?: (profileData: ShippingProfileFormData) => Promise<void>;
  updating: boolean;
}

const ShippingProfileForm: React.FC<ShippingProfileFormProps> = ({
  profile,
  selectedCompanyId,
  onSave,
  onDelete,
  updating,
}) => {
  const auth = useSelector(selectAuth);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

  const companyId: string = selectedCompanyId
    ? selectedCompanyId
    : auth!.companyId;
  const foundCompany = useSelector((state: RootState) =>
    state.companies.data.find((company) => company._id === companyId)
  );
  const companyName = foundCompany?.companyName ?? '';

  const validateTemperatureRange = (values: ShippingProfileFormData) => {
    const errors: Record<string, string> = {};

    if (
      values.minSteadyStateTempC !== null &&
      values.minSteadyStateTempC !== undefined &&
      values.maxSteadyStateTempC !== null &&
      values.maxSteadyStateTempC !== undefined &&
      Number(values.minSteadyStateTempC) >= Number(values.maxSteadyStateTempC)
    ) {
      errors.maxSteadyStateTempC = 'Must be greater than Min Temp.';
    }

    if (
      values.minCriticalTempC !== null &&
      values.minCriticalTempC !== undefined &&
      values.maxCriticalTempC !== null &&
      values.maxCriticalTempC !== undefined &&
      Number(values.minCriticalTempC) >= Number(values.maxCriticalTempC)
    ) {
      errors.maxCriticalTempC = 'Must be greater than Critical Temp. Low';
    }

    return errors;
  };

  const onConfirmDelete = (values: ShippingProfileFormData) => {
    setShowConfirmDelete(false);
    if (onDelete) {
      onDelete(values);
    }
  };

  // TODO(company-profile): be able to toggle on/off critical excursion
  const isCustomProfile = profile?.type === ProfileType.Custom;

  // for now we don't have a create profile, so there should always be a profile
  if (profile === undefined) {
    return null;
  }

  return (
    <Form<ShippingProfileFormData>
      onSubmit={onSave}
      initialValues={shippingProfileToFormData(profile)}
      validate={validateTemperatureRange}
      render={({ handleSubmit, values, valid, dirty }) => (
        <div className={styles.formContainer}>
          <div className={styles.formContent}>
            <ShippingProfileFormSection
              title="Basics"
              tooltip="Basic profile information"
              firstSection
            >
              <div className={styles.formRow}>
                {isArtyc(auth) && (
                  <TextInput
                    variant="form"
                    label="Company"
                    disabled
                    managedText={companyName}
                  />
                )}
              </div>
              <div className={styles.formRow}>
                <TextField
                  name="name"
                  label="Profile Name"
                  placeholder="Name"
                  tooltip="Unique name for quick identification and selection within the system."
                  validate={required}
                />
                <SelectField
                  name="shipperType"
                  label="Shipper Type"
                  placeholder="Select"
                  options={shipperTypeOptions}
                  tooltip="Type of shipping device to be used with this profile."
                  validate={required}
                  disabled={!isCustomProfile}
                />
              </div>
              <div className={styles.formRow}>
                <TextField
                  name="minSteadyStateTempC"
                  label="Min Temp."
                  placeholder="Enter temperature"
                  tooltip="Minimum safe temperature for this profile."
                  validate={required}
                  disabled
                />
                <TextField
                  name="maxSteadyStateTempC"
                  label="Max Temp."
                  placeholder="Enter temperature"
                  tooltip="Maximum safe temperature for this profile."
                  validate={required}
                  disabled
                />
              </div>
            </ShippingProfileFormSection>

            <ShippingProfileFormSection
              title="Monitoring Settings"
              tooltip="Configure how frequently the shipper measures and transmits temperature data"
            >
              <div className={styles.formRow}>
                <SelectField
                  name="measurementIntervalSec"
                  label="Measurement Interval"
                  placeholder="Select"
                  options={getIntervalOptions(values.shipperType)}
                  tooltip="Frequency at which temperature measurements are taken."
                  validate={required}
                />
                <SelectField
                  name="transmissionIntervalSec"
                  label="Transmission Interval"
                  placeholder="Select"
                  options={getIntervalOptions(values.shipperType)}
                  tooltip="Frequency at which measurements are transmitted to the server."
                  validate={required}
                />
              </div>
            </ShippingProfileFormSection>

            <ShippingProfileFormSection
              title="Excursion Windows"
              tooltip="Configure time windows for steady state and cooldown excursions"
            >
              <div className={styles.formRow}>
                <SelectField
                  name="steadyStateWindowSec"
                  label="Steady State Window"
                  placeholder="Select"
                  options={steadyStateWindowOptions}
                  tooltip="At steady state, if the temperature exceeds the safe temperature range for this duration, an excursion will be flagged"
                  validate={required}
                />
                <SelectField
                  name="cooldownWindowSec"
                  label="Cooldown Window"
                  placeholder="Select"
                  options={cooldownWindowOptions}
                  tooltip="During initial cooldown, if the temperature does not reach the safe temperature range by this duration, an excursion will be flagged"
                  validate={required}
                />
              </div>
            </ShippingProfileFormSection>

            <ShippingProfileFormSection
              title="Critical Excursion"
              tooltip="Configure critical excursion temperature thresholds"
            >
              <div className={styles.formRow}>
                <TextField
                  name="minCriticalTempC"
                  label="Critical Temp. Low"
                  placeholder="Enter temperature"
                  tooltip="If the temperature gets below this threshold at all, an excursion will be flagged"
                />
                <TextField
                  name="maxCriticalTempC"
                  label="Critical Temp. High"
                  placeholder="Enter temperature"
                  tooltip="If the temperature gets above this threshold at all, an excursion will be flagged"
                />
              </div>
            </ShippingProfileFormSection>

            <ShippingProfileFormSection
              title="Additional Information"
              tooltip="Add any notes or additional information"
            >
              <TextArea
                label="Notes"
                value={values.notes || ''}
                onChange={(value) => (values.notes = value)}
                placeholder="Add any additional info here"
              />
            </ShippingProfileFormSection>
          </div>

          <div className={styles.footer}>
            <div className={styles.footerButtons}>
              {onDelete && isCustomProfile && (
                <>
                  <Button
                    variant="secondaryError"
                    label="Delete"
                    onClick={() => setShowConfirmDelete(true)}
                    loading={updating}
                  />
                  <NoticeModal
                    title="Are you sure?"
                    notice="This action cannot be undone."
                    show={showConfirmDelete}
                    onClose={() => setShowConfirmDelete(false)}
                    buttons={
                      <>
                        <Button
                          variant="secondary"
                          label="Cancel"
                          onClick={() => setShowConfirmDelete(false)}
                        />
                        <Button
                          variant="secondaryError"
                          label="Delete"
                          onClick={() => onConfirmDelete(values)}
                        />
                      </>
                    }
                  />
                </>
              )}
              <Button
                variant="primary"
                label="Save"
                onClick={handleSubmit}
                disabled={!valid || !dirty}
                loading={updating}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default ShippingProfileForm;

import React, { useCallback, useState, useRef } from 'react';
import { Field } from 'react-final-form';
import TextField from '../../../components/forms/TextField';
import { FieldValidator } from 'final-form';
import debounce from 'lodash/debounce';
import styles from './AddressAutocompleteField.module.scss';
import axios from 'axios';

export interface MapboxAddress {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  latitude: number;
  longitude: number;
}

interface MapboxFeature {
  place_name: string;
  geometry: {
    coordinates: [number, number];
  };
  context: Array<{
    id: string;
    text: string;
    short_code?: string;
  }>;
  address?: string;
  text: string;
}

interface MapboxResponse {
  features: MapboxFeature[];
}

interface MapboxOption {
  value: string;
  label: string;
  address: MapboxAddress;
}

const MAPBOX_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAP_TOKEN;

const parseMapboxFeature = (feature: MapboxFeature): MapboxAddress => {
  // Extract city, state, and postal code from context array
  const addressComponents = feature.context.reduce(
    (acc, ctx) => {
      if (ctx.id.startsWith('place')) {
        acc.city = ctx.text;
      } else if (ctx.id.startsWith('region')) {
        acc.state = ctx.short_code?.replace('US-', '') || '';
      } else if (ctx.id.startsWith('postcode')) {
        acc.postalCode = ctx.text;
      }
      return acc;
    },
    {
      city: '',
      state: '',
      postalCode: '',
    }
  );

  return {
    addressLine1: feature.address
      ? `${feature.address} ${feature.text}`
      : feature.text,
    ...addressComponents,
    latitude: feature.geometry.coordinates[1],
    longitude: feature.geometry.coordinates[0],
  };
};

type AddressAutocompleteFieldProps = {
  name: string;
  validate?: FieldValidator<string>;
  onAddressSelect?: (address: MapboxAddress) => void;
  label?: string;
  showOptional?: boolean;
  showRequired?: boolean;
  tooltip?: string;
};

const AddressAutocompleteField = ({
  name,
  validate,
  onAddressSelect,
  ...props
}: AddressAutocompleteFieldProps) => {
  const [options, setOptions] = useState<MapboxOption[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const fetchAddresses = useCallback(
    debounce(async (query: string) => {
      if (!MAPBOX_ACCESS_TOKEN || query.length < 3) {
        setOptions([]);
        setIsOpen(false);
        return;
      }

      try {
        const { data } = await axios.get<MapboxResponse>(
          `${MAPBOX_BASE_URL}/${encodeURIComponent(query)}.json`,
          {
            params: {
              access_token: MAPBOX_ACCESS_TOKEN,
              country: 'US',
              types: 'address',
              limit: 5,
            },
          }
        );

        const newOptions = data.features.map((feature) => {
          const address = parseMapboxFeature(feature);
          return {
            value: feature.place_name,
            label: feature.place_name,
            address,
          };
        });

        setOptions(newOptions);
        setIsOpen(newOptions.length > 0);
      } catch (error) {
        console.error('Error fetching addresses:', error);
        setOptions([]);
        setIsOpen(false);
      }
    }, 300),
    []
  );

  return (
    <Field
      name={name}
      validate={validate}
      render={({ input }) => {
        const handleOptionSelect = (option: MapboxOption) => {
          input.onChange(option.value);
          onAddressSelect?.(option.address);
          setIsOpen(false);
        };

        return (
          <div className={styles.container}>
            <TextField
              {...props}
              {...input}
              onChange={(value: string) => {
                input.onChange(value);
                fetchAddresses(value);
              }}
            />
            {isOpen && options.length > 0 && (
              <div
                className={styles.dropdown}
                ref={dropdownRef}
                onMouseLeave={() => setIsOpen(false)}
              >
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={styles.option}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default AddressAutocompleteField;

import { AxiosInstance, AxiosResponse } from 'axios';
import { patch, post } from '../hooks/useAxiosPrivate';
import { CompanyEntity, SalesModelEnum } from '../state/companies/types';
import { JourneyTypeEnum } from '../state/journeys/types';
import { DataPayload } from '../state/types';
import { genericFetch } from './api';
import { Auth } from '../state/auth/types';

const ARTYC_COMPANY_URL = '/artyc/companies';
const companyUrl = (auth: Auth | null) => `/api/v1/company/${auth?.companyId}`;

const CompaniesApi = {
  getCompanies: async (
    axios: AxiosInstance,
    auth: Auth | null
  ): Promise<DataPayload<CompanyEntity>> => {
    const response = await genericFetch<DataPayload<CompanyEntity>>(
      axios,
      auth,
      ARTYC_COMPANY_URL,
      companyUrl(auth),
      {
        paginate: 'false',
      }
    );

    return response;
  },
  createCompany: async (
    axios: AxiosInstance,
    companyName: string,
    salesModel: SalesModelEnum,
    defaultJourneyType: JourneyTypeEnum
  ) => {
    const response: AxiosResponse<CompanyEntity> = await post(
      axios,
      ARTYC_COMPANY_URL,
      {
        companyName,
        salesModel,
        defaultJourneyType,
      }
    );
    return response.data;
  },
  updateCompany: async (
    axios: AxiosInstance,
    companyId: string,
    companyName: string,
    salesModel: SalesModelEnum,
    defaultJourneyType: JourneyTypeEnum
  ) => {
    const response: AxiosResponse<{ success: string }> = await patch(
      axios,
      `${ARTYC_COMPANY_URL}/${companyId}`,
      {
        companyName,
        salesModel,
        defaultJourneyType,
      }
    );
    return response.data;
  },
};

export default CompaniesApi;

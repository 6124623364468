import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import Checkbox from '../../../aurora/components/Checkbox/Checkbox';
import { Button } from '../../../aurora/components/Button/legacy/Button';
import styles from '../../../components/layout/Splash.module.scss';
import { showToast } from '../../../aurora/components/Toast/Toast';

const LOGIN_URL = '/auth/login';

// TODO: refactor form to use final-form components
const LoginForm = () => {
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    setIsButtonDisabled(true);
    let errMsg: string;

    try {
      const sanitizedEmail = email.trim().toLowerCase();
      const sanitizedPassword = password.trim();
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          email: sanitizedEmail,
          password: sanitizedPassword,
          stayLoggedIn,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      const { userId } = response.data;

      // Navigate to MFA page with required state
      navigate('/auth/mfa', {
        state: { userId, stayLoggedIn },
        replace: true,
      });

      setEmail('');
      setPassword('');
    } catch (err: any) {
      const serverMessage = err?.response?.data;
      if (!err?.response) {
        errMsg = 'No Server Response';
      } else if (err.response?.status === 400) {
        errMsg = 'Email or Password Incorrect';
      } else if (err.response?.status === 401) {
        errMsg = 'Unauthorized';
      } else if (serverMessage) {
        errMsg = serverMessage;
      } else {
        errMsg = 'Login Failed';
      }
      showToast({
        type: 'error',
        title: 'Login Error',
        text: errMsg,
      });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formInputs}>
          <TextInput
            variant={'filter'}
            ref={emailRef}
            placeholder={'Enter your email'}
            label={'Email'}
            managedText={email}
            onChange={(newEmail) => setEmail(newEmail)}
            type="email"
          />
          <TextInput
            variant={'filter'}
            placeholder={'Enter your password'}
            label={'Password'}
            type="password"
            managedText={password}
            onChange={(newPassword) => setPassword(newPassword)}
          />
        </div>
        <div className={styles.actionsRow}>
          <Checkbox
            onChange={() => setStayLoggedIn(!stayLoggedIn)}
            managedChecked={stayLoggedIn}
            labelClassName={styles.checkboxLabel}
            label={'Remember for 30 days'}
          />
          <Link className={styles.blueLink} to="/forgot-password">
            Forgot password
          </Link>
        </div>
        <Button
          className={styles.formButton}
          variant="primary"
          label="Sign In"
          buttonType="submit"
          onClick={() => {}}
          disabled={isButtonDisabled}
        />
      </form>
    </>
  );
};

export default LoginForm;

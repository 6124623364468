import React, { forwardRef } from 'react';
import { Heading } from '../../../../../aurora/typography/Heading/Heading';
import { CustomerCostReportEntity } from '../../../../../state/customerCostReports/types';
import NumberToggles from './NumberToggles';
import SummaryComparison from './SummaryComparisons';
import styles from './Report.module.scss';
import ActionBar from './ActionBar';
import Considerations from './Considerations';
import PrintOnly from './PrintOnly';
import { IconLogo } from '../../../../../aurora/icons';
import { Text } from '../../../../../aurora/typography/Text/Text';

interface Props {
  report: CustomerCostReportEntity;
  printVersion: boolean;
}
const CostReport = forwardRef<HTMLDivElement, Props>(function CostReport(
  { report, printVersion },
  ref
) {
  return (
    <div className={styles.report} ref={ref}>
      <PrintOnly className={styles.logo} printVersion={printVersion}>
        <IconLogo width={142} height={36} color={'primary600'} />
      </PrintOnly>

      <ActionBar report={report} printVersion={printVersion} />
      <Heading size="h5" className={styles.header}>
        Cost comparison for {report.companyName}
      </Heading>
      <SummaryComparison report={report} />
      <NumberToggles printVersion={printVersion} />

      <Considerations report={report} />
      <PrintOnly className={styles.questions} printVersion={printVersion}>
        <Text size="sm" className={styles.questionsHeader}>
          Have questions? Let us know.
        </Text>
        <Text size="sm">Emily Harari</Text>
        <Text size="sm">Customer Success</Text>
        <Text size="sm">support@shipartyc.com</Text>
      </PrintOnly>
    </div>
  );
});

export default CostReport;

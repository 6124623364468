import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../../state/auth';
import { UserInfo } from '../../../state/auth/types';
import TextField from '../../../components/forms/TextField';
import { Button } from '../../../aurora/components/Button/Button';
import styles from '../../../components/layout/Splash.module.scss';
import { showToast } from '../../../aurora/components/Toast/Toast';
import { Form } from 'react-final-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AuthApi from '../../../api/authApi';

const RESEND_COOLDOWN = 60; // 60 seconds

interface MfaFormProps {
  userId: string;
  stayLoggedIn: boolean;
  initialCode?: string;
}

const MfaForm = ({ userId, stayLoggedIn, initialCode }: MfaFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const from = location.state?.from?.pathname || '/';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countdown, setCountdown] = useState(RESEND_COOLDOWN);
  const [isResending, setIsResending] = useState(false);
  const axios = useAxiosPrivate();

  useEffect(() => {
    if (initialCode) {
      handleSubmit({ code: initialCode });
    }
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleResend = async () => {
    setIsResending(true);
    try {
      await AuthApi.resendMfa(axios, {
        userId,
        stayLoggedIn,
      });

      showToast({
        type: 'success',
        title: 'Code Sent',
        text: 'A new verification code has been sent to your email',
      });
      setCountdown(RESEND_COOLDOWN);
    } catch (err: any) {
      const serverMessage = err?.response?.data?.message;
      showToast({
        type: 'error',
        title: 'Error',
        text: serverMessage || 'Failed to send new code',
      });
    } finally {
      setIsResending(false);
    }
  };

  const handleSubmit = async (values: { code: string }) => {
    setIsSubmitting(true);
    try {
      const { accessToken } = await AuthApi.verifyMfa(axios, {
        userId,
        code: values.code,
        stayLoggedIn,
      });

      const { userInfo } = jwt_decode<{ userInfo: UserInfo }>(accessToken);
      if (!userInfo._id) {
        throw new Error('No id in accessToken');
      }

      dispatch(setAuth({ ...userInfo, accessToken }));
      navigate(from, { replace: true });
    } catch (err: any) {
      const serverMessage = err?.response?.data?.message;
      let errMsg: string;

      if (!err?.response) {
        errMsg = 'No Server Response';
      } else if (err.response?.status === 400) {
        errMsg = 'Code is required';
      } else if (err.response?.status === 401) {
        errMsg = serverMessage || 'Invalid or expired code';
      } else {
        errMsg = 'Verification Failed';
      }

      showToast({
        type: 'error',
        title: 'Verification Error',
        text: errMsg,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formInputs}>
            <TextField
              name="code"
              label="Verification Code"
              placeholder="Enter the code from your email"
              type="text"
            />
          </div>
          <div className={styles.buttonGroup}>
            <Button
              type="primary"
              style="filled"
              size="md"
              label="Verify"
              className={styles.formButton}
              disabled={isSubmitting}
              onClick={() => {}}
            />
            <Button
              type="neutral"
              style="ghost"
              size="md"
              label={
                countdown > 0 ? `Resend Code (${countdown}s)` : 'Resend Code'
              }
              className={styles.formButton}
              disabled={countdown > 0 || isResending}
              onClick={handleResend}
            />
          </div>
        </form>
      )}
    />
  );
};

export default MfaForm;

import { genericFetch } from './api';
import { Auth } from '../state/auth/types';
import { AxiosInstance } from 'axios';
import { JourneyEntity } from '../state/journeys/types';
import { ShipmentEntity } from '../state/shipments/types';

const ARTYC_JOURNEY_URL = '/artyc/journeys';
const companyJourneysUrl = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/journeys`;

const JourneysApi = {
  getJourney: async (
    axios: AxiosInstance,
    auth: Auth | null,
    journeyId: string
  ): Promise<JourneyEntity> => {
    const response: JourneyEntity = await genericFetch(
      axios,
      auth,
      `${ARTYC_JOURNEY_URL}/${journeyId}`,
      `${companyJourneysUrl(auth)}/${journeyId}`
    );
    return response;
  },
  getShipmentsForJourney: async (
    axios: AxiosInstance,
    auth: Auth | null,
    journeyId: string
  ): Promise<ShipmentEntity[]> => {
    const response: ShipmentEntity[] = await genericFetch(
      axios,
      auth,
      `${ARTYC_JOURNEY_URL}/${journeyId}/shipments`,
      `${companyJourneysUrl(auth)}/${journeyId}/shipments`
    );
    return response;
  },
};

export default JourneysApi;

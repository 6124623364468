import React from 'react';
import StatusBadge from '../../../aurora/components/StatusBadge/StatusBadge';
import { DeviceDeploymentStatus } from '../../../state/devices/types';

interface Props {
  deploymentStatus?: DeviceDeploymentStatus;
}

const DeploymentStatusBadge = ({ deploymentStatus }: Props) => {
  const getStatusColor = (status?: DeviceDeploymentStatus) => {
    switch (status) {
      case DeviceDeploymentStatus.DEPLOYED:
        return 'blue';
      case DeviceDeploymentStatus.READY:
        return 'green';
      case DeviceDeploymentStatus.ON_STANDBY:
        return 'gray';
      default:
        return 'orange';
    }
  };

  return (
    <StatusBadge
      content={deploymentStatus || 'Unknown'}
      status={getStatusColor(deploymentStatus)}
    />
  );
};

export default DeploymentStatusBadge;

import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSnapshotsBySegment,
  setSnapshots,
  setSegment,
} from '../../../../state/segments';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import SegmentsApi from '../../../../api/segmentsApi';
import { RootState } from '../../../../state/store';
import { JourneyEntity } from '../../../../state/journeys/types';
import JourneyReport from './JourneyReport';
import ShippingProfilesApi from '../../../../api/shippingProfilesApi';
import EventsApi from '../../../../api/eventsApi';
import { ShippingProfileEntity } from '../../../../state/shippingProfile/types';
import { EventEntity } from '../../../../state/events/types';

interface Props {
  journey: JourneyEntity;
  isArtycViewer: boolean;
  onLoadComplete: () => void;
}

const JourneyReportContainer = forwardRef<HTMLDivElement, Props>(
  ({ journey, isArtycViewer, onLoadComplete }, ref) => {
    const dispatch = useDispatch();
    const axios = useAxiosPrivate();
    const auth = useSelector(selectAuth);
    const { artycSnapshotsMode } = useSelector(
      (state: RootState) => state.journeys
    );

    const segment = useSelector((root: RootState) =>
      journey.segmentId !== undefined && journey.segmentId !== null
        ? root.segments.segmentById[journey.segmentId]
        : undefined
    );
    const snapshotsBySegment = useSelector(selectSnapshotsBySegment);
    const snapshots = snapshotsBySegment[segment?._id || ''] || [];

    const [isError, setIsError] = useState(false);
    const [shippingProfile, setShippingProfile] = useState<
      ShippingProfileEntity | undefined
    >(undefined);
    const [events, setEvents] = useState<EventEntity[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        if (!journey.segmentId) return;

        setIsError(false);

        try {
          if (!segment) {
            const segmentResponse = await SegmentsApi.getSegment(
              axios,
              auth,
              journey.segmentId
            );
            dispatch(setSegment(segmentResponse));
          }

          if (segment) {
            const profileId =
              segment.shippingProfile || journey.shippingProfile;
            const [snapshots, shippingProfileResp, eventResp] =
              await Promise.all([
                SegmentsApi.getSegmentSnapshots(
                  axios,
                  segment._id,
                  isArtycViewer,
                  segment.company,
                  artycSnapshotsMode
                ),
                profileId !== undefined && profileId !== null
                  ? ShippingProfilesApi.getShippingProfile(
                      axios,
                      auth,
                      profileId
                    )
                  : Promise.resolve(undefined),
                EventsApi.getEventsForSegment(axios, auth, segment._id),
              ]);

            dispatch(setSnapshots({ [segment._id]: snapshots }));
            setShippingProfile(shippingProfileResp);
            setEvents(eventResp);
            onLoadComplete();
          }
        } catch (error) {
          setIsError(true);
        }
      };

      fetchData();
    }, [journey.segmentId, segment, isArtycViewer, artycSnapshotsMode]);

    if (!segment || isError) {
      return null;
    }

    return (
      <JourneyReport
        ref={ref}
        journey={journey}
        segment={segment}
        snapshots={snapshots}
        isArtycViewer={isArtycViewer}
        events={events}
        shippingProfile={shippingProfile}
      />
    );
  }
);

JourneyReportContainer.displayName = 'JourneyReportContainer';

export default JourneyReportContainer;

import { DeviceType } from '../../../../state/devices/types';
import {
  ProfileType,
  ShippingProfileEntity,
} from '../../../../state/shippingProfile/types';

export interface ShippingProfileFormData {
  _id?: string;
  company: string;
  name: string;
  type: ProfileType;
  shipperType: DeviceType;
  active: boolean;
  archived: boolean;
  measurementIntervalSec: string;
  transmissionIntervalSec: string;
  minSteadyStateTempC: string;
  maxSteadyStateTempC: string;
  steadyStateWindowSec: string;
  cooldownWindowSec: string;
  minCriticalTempC?: string | null;
  maxCriticalTempC?: string | null;
  notes?: string | null;
}

export const shippingProfileToFormData = (
  profile: ShippingProfileEntity
): ShippingProfileFormData => {
  return {
    _id: profile._id,
    company: profile.company,
    name: profile.name,
    type: profile.type,
    shipperType: profile.shipperType,
    active: profile.active,
    archived: profile.archived,
    measurementIntervalSec: String(profile.measurementIntervalSec),
    transmissionIntervalSec: String(profile.transmissionIntervalSec),
    minSteadyStateTempC: String(profile.minSteadyStateTempC),
    maxSteadyStateTempC: String(profile.maxSteadyStateTempC),
    steadyStateWindowSec: String(profile.steadyStateWindowSec),
    cooldownWindowSec: String(profile.cooldownWindowSec),
    minCriticalTempC:
      profile.minCriticalTempC !== undefined &&
      profile.minCriticalTempC !== null
        ? profile.minCriticalTempC.toString()
        : null,
    maxCriticalTempC:
      profile.maxCriticalTempC !== undefined &&
      profile.maxCriticalTempC !== null
        ? profile.maxCriticalTempC.toString()
        : null,
    notes: profile.notes ?? null,
  };
};

export const shippingProfileFormDataToEntity = (
  formData: ShippingProfileFormData
): ShippingProfileEntity => {
  return {
    ...formData,
    _id: formData._id ?? '',
    measurementIntervalSec: Number(formData.measurementIntervalSec),
    transmissionIntervalSec: Number(formData.transmissionIntervalSec),
    minSteadyStateTempC: Number(formData.minSteadyStateTempC),
    maxSteadyStateTempC: Number(formData.maxSteadyStateTempC),
    steadyStateWindowSec: Number(formData.steadyStateWindowSec),
    cooldownWindowSec: Number(formData.cooldownWindowSec),
    minCriticalTempC:
      formData.minCriticalTempC !== null &&
      formData.minCriticalTempC !== undefined
        ? Number(formData.minCriticalTempC)
        : undefined,
    maxCriticalTempC:
      formData.maxCriticalTempC !== null &&
      formData.maxCriticalTempC !== undefined
        ? Number(formData.maxCriticalTempC)
        : undefined,
  };
};

import React, { useState } from 'react';
import PageHeader from '../../components/layout/PageHeader';
import SectionBar from '../../components/layout/sectionBar/SectionBar';
import ShippingProfileSection from './components/ShippingProfileSection';
import AddressSection from './components/addresses/AddressSection';

const CompanyProfilePage = () => {
  const [selectedSection, setSelectedSection] =
    useState<string>('shippingProfiles');

  const sections = [
    { name: 'shippingProfiles', displayText: 'Shipping Profiles' },
    { name: 'addresses', displayText: 'Addresses' },
  ];

  const shownSection =
    selectedSection === 'shippingProfiles' ? (
      <ShippingProfileSection />
    ) : selectedSection === 'addresses' ? (
      <AddressSection />
    ) : null;

  return (
    <>
      <PageHeader headingText="Company Profile" />
      <SectionBar
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        sections={sections}
      />
      {shownSection}
    </>
  );
};

export default CompanyProfilePage;

import { OptionalField } from '../types';

export enum OrderStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FULFILLED = 'FULFILLED',
  CANCELLED = 'CANCELLED',
}

export interface OrderEntity {
  _id: string;
  createdAt: string;
  updatedAt: string;
  status: OrderStatus;
  pid: string;
  company: string;
  companyName: string;
  expectedDeliveryDate: OptionalField<string>;
  poNumber: OptionalField<string>;
  customId: OptionalField<string>;
  placedByUser: string;
  placedByUserName: string;
  fulfilledAt: OptionalField<string>;
  journeyId: OptionalField<string>;
}

export interface OrderFilters {
  searchQuery?: string;
  fulfilledAt?: {
    startDate?: string;
    endDate?: string;
  };
  expectedDeliveryDate?: {
    startDate?: string;
    endDate?: string;
  };
  createdAt?: {
    startDate?: string;
    endDate?: string;
  };
  companyIds?: string[];
  status?: OrderStatus[];
}

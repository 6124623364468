import React from 'react';
import {
  DeviationStage,
  EventEntity,
  EventTypesEnum,
  TemperatureExcursionDetails,
} from '../../../../state/events/types';
import { Label } from '../../../../aurora/typography/Label/Label';
import styles from './EventsTable.module.scss';
import { formatDateTime } from '../../../../utils/dateUtil';
import { formatDuration, intervalToDuration } from 'date-fns';
import { formatEnumToReadableText } from '../../../../utils/formatEnumToReadableText';

interface Props {
  events: EventEntity[];
}

const EventsTable = ({ events }: Props) => {
  if (events.length === 0) {
    return null;
  }

  return (
    <div className={styles.tableContainer}>
      <Label size="sm" color="strong900">
        Events
      </Label>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Event Type</th>
            <th>Event ID</th>
            <th>Date</th>
            <th>Duration</th>
            <th>Temperature</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => {
            let description = '';
            let recordedTemp: number | undefined;
            if (event.eventType === EventTypesEnum.TEMPERATURE_EXCURSION) {
              const details = event.eventDetails as
                | TemperatureExcursionDetails
                | undefined;
              if (details?.deviationStage === DeviationStage.POST_COOLDOWN) {
                description = 'Shipper experienced a steady state excursion.';
              }
              recordedTemp = details?.deviationTempC;
            }

            let duration = '-';
            if (event.eventEnd) {
              const interval = intervalToDuration({
                start: new Date(event.eventStart),
                end: new Date(event.eventEnd),
              });

              duration = formatDuration(interval, {
                format: ['weeks', 'days', 'hours', 'minutes'],
              });

              // just seconds
              if (duration === '') {
                duration = `${interval.seconds} seconds`;
              }
            }

            return (
              <tr key={index}>
                <td>{formatEnumToReadableText(event.eventType)}</td>
                <td>{event.pid}</td>
                <td>{formatDateTime(event.eventStart)}</td>
                <td>{duration}</td>
                <td>
                  {recordedTemp !== undefined
                    ? `${recordedTemp.toFixed(2)}°C`
                    : '-'}
                </td>
                <td>{description || '-'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EventsTable;

import React from 'react';
import { Text } from '../../../../aurora/typography/Text/Text';
import {
  Button,
  ButtonType,
  ButtonStyle,
  ButtonSize,
} from '../../../../aurora/components/Button/Button';
import { ShippingProfileEntity } from '../../../../state/shippingProfile/types';
import { secondsToFullMinutes } from '../../../../utils/dateUtil';
import { IconClock, IconSnow, IconCube } from '../../../../aurora/icons';
import Switch from '../../../../aurora/components/Switch/Switch';
import styles from './ProfileCard.module.scss';
import { Label } from '../../../../aurora/typography/Label/Label';

interface Props {
  profile: ShippingProfileEntity;
  onEdit: () => void;
  onToggleActive?: (active: boolean) => void;
}

const ProfileCard = ({ profile, onEdit, onToggleActive }: Props) => {
  // const isCustom = profile.type === ProfileType.Custom;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <Label size="md" color="strong900">
          {profile.name}
        </Label>
        {/* <div className={isCustom ? styles.customType : styles.standardType}>
          {`• ${profile.type}`}
        </div> */}
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardBodyRow}>
          <Text size="sm" color="sub600">
            Shipper Type
          </Text>
          <Text size="sm" className={styles.valueRow}>
            <IconCube width={16} height={16} />
            {profile.shipperType}
          </Text>
        </div>
        <div className={styles.cardBodyRow}>
          <Text size="sm" color="sub600">
            Temperature Range
          </Text>
          <Text size="sm" className={styles.valueRow}>
            <IconSnow width={16} height={16} />
            {`${profile.minSteadyStateTempC}°C to ${profile.maxSteadyStateTempC}°C`}
          </Text>
        </div>
        <div className={styles.cardBodyRow}>
          <Text size="sm" color="sub600">
            Measurement Interval
          </Text>
          <Text size="sm" className={styles.valueRow}>
            <IconClock width={16} height={16} />
            {`${secondsToFullMinutes(profile.measurementIntervalSec)} minutes`}
          </Text>
        </div>
      </div>
      <div className={styles.cardFooter}>
        <div className={styles.footerLeft}>
          <Switch
            isSwitchOn={profile.active}
            onChange={(checked) => onToggleActive?.(checked)}
          />
          <Text size="sm" color="strong900">
            Active
          </Text>
        </div>
        <div className={styles.footerRight}>
          <Button
            type={ButtonType.Primary}
            style={ButtonStyle.Ghost}
            size={ButtonSize.Small}
            label="Edit"
            onClick={onEdit}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;

import React from 'react';
import { useLocation, Navigate, useSearchParams } from 'react-router-dom';
import Splash from '../../components/layout/Splash';
import MfaForm from './components/MfaForm';
import { IconLogo } from '../../aurora/icons';

const MfaPage = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const state = location.state as {
    userId: string;
    stayLoggedIn: boolean;
  } | null;

  // Handle both regular and HTML-encoded parameters
  const rawSearch = location.search.replace(/&amp;/g, '&');
  const decodedParams = new URLSearchParams(rawSearch);

  // Get values from either state, query parameters, or decoded parameters
  const code = searchParams.get('code') || decodedParams.get('code');
  const userId =
    state?.userId ||
    decodeURIComponent(
      searchParams.get('userId') || decodedParams.get('userId') || ''
    );
  const stayLoggedIn =
    state?.stayLoggedIn ||
    searchParams.get('stayLoggedIn') === 'true' ||
    decodedParams.get('stayLoggedIn') === 'true';

  if (!userId) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Splash
      card="Enter verification code"
      subtitle={`A verification code has been sent to your email. The code will expire in 15 minutes.`}
      icon={<IconLogo width={116} height={32} />}
      iconType="logo"
    >
      <MfaForm
        userId={userId}
        stayLoggedIn={stayLoggedIn}
        initialCode={code || ''}
      />
    </Splash>
  );
};

export default MfaPage;

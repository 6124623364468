import React, { useEffect, useState } from 'react';
import { SelectOptionType } from '../../../../aurora/components/Select/Select';
import ShippingProfilesApi from '../../../../api/shippingProfilesApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../state/auth';
import { useField, useForm } from 'react-final-form';
import SelectField from '../../../../components/forms/SelectField';
import { required } from '../../../../components/forms/validators';
import { DeviceType } from '../../../../state/devices/types';

interface Props {
  shipperType: DeviceType;
}
const ShippingProfileSelect = ({ shipperType }: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const [shippingProfileOptions, setShippingProfileOptions] = useState<
    SelectOptionType[]
  >([]);
  const {
    input: { value: company },
  } = useField('company', { subscription: { value: true } });

  const form = useForm();
  const resetProfile = () => {
    setShippingProfileOptions([]);
  };

  const fetchProfiles = async (companyId: string) => {
    resetProfile();

    const profiles = await ShippingProfilesApi.getShippingProfiles(
      axios,
      auth,
      companyId
    );
    const profileOptions = profiles
      .filter((profile) => profile.shipperType === shipperType)
      .map((profile) => ({
        value: profile._id,
        label: profile.name,
      }));
    setShippingProfileOptions(profileOptions);
  };

  // assumes for company users, the company is set in the form
  // shipping profile depends on the company. unset if company changes + fetch new profiles
  const missingCompany = company === '' || company === undefined;
  useEffect(() => {
    if (missingCompany) {
      resetProfile();
      form.change('shippingProfile', undefined);
    } else {
      fetchProfiles(company);
    }
  }, [company]);

  return (
    <SelectField
      name="shippingProfile"
      options={shippingProfileOptions}
      label="Shipping Profile"
      disabled={missingCompany}
      placeholder={missingCompany ? 'Select Company First' : 'Select'}
      validate={required}
    />
  );
};

export default ShippingProfileSelect;

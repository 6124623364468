import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogout from './useLogout';

export const DEFAULT_IDLE_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds

export const MONITORED_EVENTS = [
  'mousedown',
  'mousemove',
  'keypress',
  'scroll',
  'touchstart',
] as const;

interface UseIdleTimeoutOptions {
  timeout?: number;
  onIdle?: () => void | Promise<void>;
}

const useIdleTimeout = ({
  timeout = DEFAULT_IDLE_TIMEOUT,
  onIdle,
}: UseIdleTimeoutOptions = {}) => {
  const navigate = useNavigate();
  const logout = useLogout();

  const handleIdle = useCallback(() => {
    if (onIdle) {
      onIdle();
    } else {
      logout();
      navigate('/login', { replace: true });
    }
  }, [logout, navigate, onIdle]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleIdle, timeout);
    };

    const eventHandler = () => {
      resetTimer();
    };

    MONITORED_EVENTS.forEach((event) => {
      window.addEventListener(event, eventHandler);
    });

    // Initial timer setup
    resetTimer();

    return () => {
      clearTimeout(timeoutId);
      MONITORED_EVENTS.forEach((event) => {
        window.removeEventListener(event, eventHandler);
      });
    };
  }, [handleIdle, timeout]);
};

export default useIdleTimeout;

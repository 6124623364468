import React, { useEffect, useState } from 'react';
import { IconDownload } from '../../../../aurora/icons';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../../../state/auth';
import { downloadCsvAction } from '../../../../state/segments';
import { AppDispatch } from '../../../../state/store';
import { JourneyEntity, JourneyStatus } from '../../../../state/journeys/types';
import { Button } from '../../../../aurora/components/Button/Button';
import ActionButton from '../../../../components/table/ActionButton';
import styles from './ExportJourneyButton.module.scss';
import JourneyReportContainer from './JourneyReportContainer';
import { usePDF } from 'react-to-pdf';
import ExportModal from './ExportModal';

interface Props {
  journey: JourneyEntity;
  isArtycViewer: boolean;
  iconOnly?: boolean;
}

const ExportJourneyButton = ({
  journey,
  isArtycViewer,
  iconOnly = false,
}: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch<AppDispatch>();
  const { toPDF, targetRef } = usePDF();

  const [showModal, setShowModal] = useState(false);
  const [logType, setLogType] = useState<string>('customer');
  const [exportFormat, setExportFormat] = useState<string>('csv');
  const [reportLoaded, setReportLoaded] = useState(false);

  useEffect(() => {
    if (!showModal) {
      setReportLoaded(false);
      setLogType('customer');
      setExportFormat('csv');
    }
  }, [showModal]);

  // can only download if there's a segment
  if (
    journey.segmentId === undefined ||
    journey.status === JourneyStatus.PENDING
  ) {
    return null;
  }

  const handleExport = async () => {
    if (exportFormat === 'csv') {
      dispatch(
        downloadCsvAction({
          axios: axiosPrivate,
          auth,
          journeys: [journey],
          fullLog: logType === 'full' && isArtycViewer,
          isArtycViewer,
        })
      );
      setShowModal(false);
    } else if (exportFormat === 'pdf' && reportLoaded) {
      try {
        const { current } = targetRef;
        if (current) {
          await toPDF({
            filename: `${journey.pid}_shipment_report.pdf`,
            method: 'save',
            resolution: 2,
            page: {
              format: 'a4',
              orientation: 'portrait',
            },
            canvas: {
              useCORS: true,
              logging: true,
            },
          });
        }
        setShowModal(false);
      } catch (error) {
        console.error('PDF generation error:', error);
      }
    }
  };

  const button = iconOnly ? (
    <ActionButton
      label="Export"
      icon={IconDownload}
      onClick={() => setShowModal(true)}
    />
  ) : (
    <Button
      label="Export"
      type="neutral"
      style="stroke"
      size="xxs"
      onClick={() => setShowModal(true)}
    />
  );

  const reportLoading = exportFormat === 'pdf' && !reportLoaded;

  return (
    <>
      {button}
      {exportFormat === 'pdf' && (
        <div className={styles.reportContainer}>
          <JourneyReportContainer
            journey={journey}
            ref={targetRef}
            isArtycViewer={isArtycViewer}
            onLoadComplete={() => setReportLoaded(true)}
          />
        </div>
      )}
      {showModal && (
        <ExportModal
          show={showModal}
          onClose={() => setShowModal(false)}
          logType={logType}
          setLogType={setLogType}
          exportFormat={exportFormat}
          setExportFormat={setExportFormat}
          onExport={handleExport}
          isArtycViewer={isArtycViewer}
          reportLoading={reportLoading}
        />
      )}
    </>
  );
};

export default ExportJourneyButton;

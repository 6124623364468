import React from 'react';
import {
  ReportType,
  SegmentSnapshotEntity,
} from '../../../../state/segmentSnapshots/types';
import { formatDateTime } from '../../../../utils/dateUtil';
import {
  getSnapshotInternalTemp,
  getSnapshotExternalTemp,
  getSnapshotSoc,
} from '../../../../utils/segmentSnapshot/data';
import { DeviceType } from '../../../../state/devices/types';
import styles from './SnapshotsTable.module.scss';
import { Text } from '../../../../aurora/typography/Text/Text';
import { Label } from '../../../../aurora/typography/Label/Label';

interface Props {
  snapshots: SegmentSnapshotEntity[];
  shipperType: DeviceType;
}

const MAX_SAMPLES = 400;
const importantReportTypes = new Set([
  ReportType.LidOpenLog,
  ReportType.LidCloseLog,
  ReportType.EnterTempRange,
  ReportType.ExitTempRange,
  ReportType.ExcursionLog,
]);

// pdf goes black if there are too many pages
// the table is the largest part, so we limit how big the table is
const sampleSnapshots = (
  snapshots: SegmentSnapshotEntity[],
  maxSamples: number
) => {
  if (snapshots.length <= maxSamples) {
    return snapshots;
  }

  const { importantSnapshots, remainingSnapshots } = snapshots.reduce(
    (acc, snapshot) => {
      if (importantReportTypes.has(snapshot.meta.report)) {
        acc.importantSnapshots.push(snapshot);
      } else {
        acc.remainingSnapshots.push(snapshot);
      }
      return acc;
    },
    { importantSnapshots: [], remainingSnapshots: [] } as {
      importantSnapshots: SegmentSnapshotEntity[];
      remainingSnapshots: SegmentSnapshotEntity[];
    }
  );

  // If we already have more important snapshots than our limit, evenly sample from those
  if (importantSnapshots.length >= maxSamples) {
    const step = Math.floor(importantSnapshots.length / maxSamples);
    return importantSnapshots
      .filter((_, index) => index % step === 0)
      .slice(0, maxSamples);
  }

  // Calculate how many additional samples we need
  const additionalSamplesNeeded = maxSamples - importantSnapshots.length;

  // Evenly sample from remaining snapshots
  const step = Math.floor(remainingSnapshots.length / additionalSamplesNeeded);
  const sampledRegularSnapshots = remainingSnapshots
    .filter((_, index) => index % step === 0)
    .slice(0, additionalSamplesNeeded);

  // Combine important and sampled snapshots, sort by timestamp
  return [...importantSnapshots, ...sampledRegularSnapshots].sort((a, b) =>
    a.timestamp.localeCompare(b.timestamp)
  );
};

const SnapshotsTable = ({ snapshots, shipperType }: Props) => {
  const sampleData = sampleSnapshots(snapshots, MAX_SAMPLES);

  if (sampleData.length === 0) {
    return null;
  }

  return (
    <div className={styles.tableContainer}>
      <Label size="sm" color="strong900">
        Temperature Data
      </Label>
      {sampleData.length < snapshots.length && (
        <Text size="sm">
          This dataset is a sample of the entirety to fit within the PDF page
          limit. Please export as CSV if you want to view it in full.
        </Text>
      )}
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Internal Temp (°C)</th>
            <th>External Temp (°C)</th>
            <th>Battery %</th>
            <th>Lid</th>
            <th>Event</th>
          </tr>
        </thead>
        <tbody>
          {sampleData.map((snapshot, index) => {
            return (
              <tr key={index}>
                <td>{formatDateTime(snapshot.timestamp)}</td>
                <td>{getSnapshotInternalTemp(snapshot, shipperType)}</td>
                <td>{getSnapshotExternalTemp(snapshot, shipperType)}</td>
                <td>{getSnapshotSoc(snapshot, shipperType)}</td>
                <td>{snapshot.lidOpen ? 'Open' : 'Closed'}</td>
                <td>{snapshot.reportType || '-'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SnapshotsTable;

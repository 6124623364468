import React from 'react';
import {
  AddressEntity,
  AddressTypeEnum,
} from '../../../../state/addresses/types';
import { showToast } from '../../../../aurora/components/Toast/Toast';
import {
  Button,
  ButtonType,
  ButtonStyle,
} from '../../../../aurora/components/Button/Button';
import { Form, useFormState } from 'react-final-form';
import { required } from '../../../../components/forms/validators';
import SelectField from '../../../../components/forms/SelectField';
import { capitalize } from '../../../../utils/stringUtil';
import formStyles from '../../../../components/forms/Form.module.scss';
import isEqual from 'lodash/isEqual';
import AddressFormSection from '../../../orders/components/orderForm/AddressFormSection';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../../../../state/auth';
import AddressesApi from '../../../../api/addressesApi';
import Slideout from '../../../../aurora/components/Slideout/Slideout';
import { RootState } from '../../../../state/store';
import { loadAddresses } from '../../../../state/addresses';
import { sanitizePhoneNumber } from '../../../../utils/addressUtil';
import { mapEntityToFormData, mapFormDataToEntity } from './addressFormUtils';
import { AddressFormData } from './types';
import { FormApi } from 'final-form';

type FormMode = 'add' | 'edit';

interface FormContentProps {
  handleSubmit: () => void;
  form: FormApi<AddressFormData>;
  closeSlideout: () => void;
  onDelete?: (address: AddressEntity) => void;
  mode: FormMode;
  initialValues?: AddressFormData;
  initialAddress?: AddressEntity;
}

const FormContent = ({
  handleSubmit,
  form,
  closeSlideout,
  onDelete,
  mode,
  initialValues,
  initialAddress,
}: FormContentProps) => {
  const { values } = useFormState({ subscription: { values: true } });
  const addressTypes = Object.values(AddressTypeEnum);

  const isFormUnchanged =
    mode === 'edit' &&
    initialValues &&
    values &&
    (() => {
      // Compare everything except phone numbers
      const { address: currentAddress, ...currentRest } = values;
      const { address: initialAddress, ...initialRest } = initialValues;

      const { phoneNumber: currentPhone, ...currentAddressRest } =
        currentAddress;
      const { phoneNumber: initialPhone, ...initialAddressRest } =
        initialAddress;

      // Compare non-phone values
      if (
        !isEqual(currentRest, initialRest) ||
        !isEqual(currentAddressRest, initialAddressRest)
      ) {
        return false;
      }

      // Compare sanitized phone numbers
      const currentSanitized = sanitizePhoneNumber(currentPhone);
      const initialSanitized = sanitizePhoneNumber(initialPhone);
      return currentSanitized === initialSanitized;
    })();

  return (
    <Slideout
      open={true}
      onClose={() => {
        form.reset();
        closeSlideout();
      }}
      title={mode === 'add' ? 'Add Address' : 'Edit Address'}
      footer={
        <div className={formStyles.formRow}>
          {mode === 'add' ? (
            <>
              <Button
                type={ButtonType.Neutral}
                style={ButtonStyle.Stroke}
                size="md"
                label="Cancel"
                onClick={() => {
                  form.reset();
                  closeSlideout();
                }}
                className={formStyles.flexGrow}
              />
              <Button
                type={ButtonType.Primary}
                style={ButtonStyle.Filled}
                size="md"
                label="Add"
                onClick={handleSubmit}
                className={formStyles.flexGrow}
              />
            </>
          ) : (
            <>
              <Button
                type={ButtonType.Error}
                style={ButtonStyle.Ghost}
                size="md"
                label="Delete"
                onClick={() => {
                  if (initialAddress && onDelete) {
                    onDelete(initialAddress);
                  }
                }}
                className={formStyles.flexGrow}
              />
              <Button
                type={ButtonType.Primary}
                style={ButtonStyle.Filled}
                size="md"
                label="Save"
                onClick={handleSubmit}
                disabled={isFormUnchanged}
                className={formStyles.flexGrow}
              />
            </>
          )}
        </div>
      }
    >
      <div>
        <SelectField
          label="Type"
          name="addressType"
          options={addressTypes.map((type) => ({
            value: type,
            label: capitalize(type),
          }))}
          placeholder="Select"
          validate={required}
        />
        <AddressFormSection
          heading=""
          isArtycViewer={false}
          addressType={values?.addressType}
          shipmentPrefix="address"
          includeTracking={false}
          useCompanyFromForm={false}
          canSelectExistingAddress={false}
        />
      </div>
    </Slideout>
  );
};

interface Props {
  open: boolean;
  closeSlideout: () => void;
  companyId?: string;
  initialAddress?: AddressEntity;
  onDelete?: (address: AddressEntity) => void;
}

const AddressFormSlideout = ({
  open,
  closeSlideout,
  companyId,
  initialAddress,
  onDelete,
}: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const addressesState = useSelector((state: RootState) => state.addresses);
  const mode: FormMode = initialAddress ? 'edit' : 'add';

  const handleSubmit = async (values: AddressFormData) => {
    if (!companyId) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'Please select a company first',
      });
      return;
    }

    if (!auth) {
      showToast({
        type: 'error',
        title: 'Error',
        text: 'Unable to process your request',
      });
      return;
    }

    try {
      if (mode === 'add') {
        await AddressesApi.createAddress(
          axios,
          auth,
          mapFormDataToEntity(values),
          companyId
        );
        showToast({
          type: 'success',
          title: 'Success',
          text: 'Address created successfully',
        });
      } else {
        await AddressesApi.updateAddress(
          axios,
          auth,
          initialAddress!._id,
          {
            ...mapFormDataToEntity(values),
            lastModifiedBy: auth._id,
          },
          companyId
        );
        showToast({
          type: 'success',
          title: 'Success',
          text: 'Address updated successfully',
        });
      }

      // Refresh the data
      dispatch(await loadAddresses(axios, auth, addressesState));

      closeSlideout();
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Error',
        text:
          mode === 'add'
            ? 'Failed to create address. Please try again.'
            : 'Failed to update address. Please try again.',
      });
    }
  };

  if (!open) {
    return null;
  }

  const initialValues = initialAddress
    ? mapEntityToFormData(initialAddress)
    : undefined;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => (
        <FormContent
          handleSubmit={handleSubmit}
          form={form}
          closeSlideout={closeSlideout}
          onDelete={onDelete}
          mode={mode}
          initialValues={initialValues}
          initialAddress={initialAddress}
        />
      )}
    />
  );
};

export default AddressFormSlideout;

import React from 'react';
import Filter, {
  PopoverContentProps,
} from '../../../aurora/components/Filter/Filter';
import styles from './MultiSelectFilter.module.scss';
import MultiSelectPopover from './MultiSelectPopover';

interface MultiSelectFilterProps<T> {
  options: T[];
  displayOptions?: string[];
  selectedOptions: T[];
  setOptionsAction: (options?: T[]) => any;
  placeholder: string;
}

const MultiSelectFilter = <T extends string>({
  options,
  displayOptions,
  selectedOptions = [],
  setOptionsAction,
  placeholder,
}: MultiSelectFilterProps<T>) => {
  const onClear = () => {
    if (selectedOptions.length !== 0) {
      setOptionsAction(undefined);
    }
  };

  const onFilter = (selected: T[]) => setOptionsAction(selected);

  const toFiltersSummary = (selected: T[]) => selected.length.toString();

  const popover = ({
    onSubmit,
    onCancel,
    onClear,
  }: PopoverContentProps<T[]>) => (
    <MultiSelectPopover
      entityTypes={options}
      displayEntityTypes={displayOptions || options}
      filteredEntityTypes={selectedOptions || []}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onClear={onClear}
    />
  );

  if (options === null || options.length <= 1) {
    return null;
  }

  return (
    <Filter
      placeholder={placeholder}
      popoverContent={popover}
      onSubmit={onFilter}
      onClear={onClear}
      toFiltersSummary={toFiltersSummary}
      className={styles.entityTypeFilter}
    />
  );
};

export default MultiSelectFilter;

import { DeviceType } from '../devices/types';
import { SegmentSnapshotEntity } from '../segmentSnapshots/types';
import { OptionalField } from '../types';

export enum JourneyTypeEnum {
  INTRA_CAMPUS = 'Intra-Campus',
  SLOPE = 'Slope',
  SHIPPABLE = 'Shippable',
}

export enum JourneyStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export interface JourneyEntity {
  _id: string;
  createdAt: string;
  status: JourneyStatus;
  currentLeg: number;
  totalLegs: number;
  totalEvents: number;

  // assigned when journey is created
  journeyType: JourneyTypeEnum;
  pid: string;
  company: string;
  companyName: string;
  logIncrement: number;
  setTemperature: number;
  shippingProfile: OptionalField<string>;
  order?: string;

  // decided as journey happens
  segmentId: OptionalField<string>;
  device: OptionalField<string>;
  deviceType: OptionalField<string>;
  serialNumber: OptionalField<string>;
  firmwareVersion: OptionalField<string>;
  isStartTimeEstimated: OptionalField<boolean>;
  startTime: OptionalField<string>;
  endTime: OptionalField<string>;
}

export interface JourneyFilters {
  searchQuery?: string;
  startDate?: string;
  endDate?: string;
  companyIds?: string[];
  deviceTypes?: DeviceType[];
}

export interface ShippingProfile {
  id: string;
  name: string;
  samplingRateSec: number;
  minSteadyStateTempC: number;
  maxSteadyStateTempC: number;
  // excursionWindowSec: number;
  // cooldownTimeoutSec: number;
  // minCriticalTempC?: number;
  // maxCriticalTempC?: number;
}

export type HoverTrigger = 'graph' | 'map' | 'event';
export interface HoveredSnapshot {
  snapshot: SegmentSnapshotEntity;
  trigger: HoverTrigger;
  index: number;
}

import React, { ComponentType, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import NavBadge from './NavBadge';
import { IconProps } from '../../../aurora/icons/IconBase';
import styles from './NavItem.module.scss';
import { Role } from '../../../state/auth/types';
import { Tooltip } from '../../../aurora/components/Tooltip/Tooltip';

export interface NavItemProps {
  icon: ComponentType<IconProps>;
  name: string;
  roles?: Role[];
  onClick?: () => void;
  badgeValue?: string;
  toggleSlideout?: () => void;
  shouldRender?: boolean;
  isNarrow?: boolean;
  isCollapsed?: boolean;
}

const NavItem = ({
  name,
  onClick,
  badgeValue,
  icon: Icon,
  toggleSlideout,
  isNarrow,
  isCollapsed,
}: NavItemProps) => {
  const itemRef = useRef(null);

  const row = (isActive: boolean) => (
    <>
      {isNarrow ? (
        <Tooltip label={name} placement="right" variant="light">
          <Icon
            width={22}
            height={22}
            color="currentColor"
            className={styles.navIcon}
          />
        </Tooltip>
      ) : (
        <>
          <Icon
            width={22}
            height={22}
            color="currentColor"
            className={styles.navIcon}
          />
          <div className={styles.navItemText}>{name}</div>
          {badgeValue && <NavBadge text={badgeValue} isActive={isActive} />}
        </>
      )}
    </>
  );

  const navItemClass = `${styles.navItem} ${
    isCollapsed ? styles.collapsed : ''
  }`;

  if (onClick) {
    return (
      <div
        ref={itemRef}
        onClick={() => {
          onClick();
          toggleSlideout && toggleSlideout();
        }}
        className={navItemClass}
      >
        {row(false)}
      </div>
    );
  }

  const path = '/portal/' + name.replace(/ +/g, '-').toLowerCase();

  return (
    <NavLink
      ref={itemRef}
      to={path}
      className={({ isActive }) =>
        `${navItemClass} ${isActive ? styles.activeItem : ''}`
      }
      onClick={toggleSlideout && toggleSlideout}
    >
      {({ isActive }) => row(isActive)}
    </NavLink>
  );
};

export default NavItem;

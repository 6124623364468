import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import NavBar from './NavBar/NavBar';
import styles from './Portal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../state/auth';
import { loadCompanies } from '../../state/companies';
import { RootState } from '../../state/store';
import DevicesApi from '../../api/devicesApi';
import { setDeviceTypeList } from '../../state/devices';
import useIdleTimeout from '../../hooks/useIdleTimeout';

const Portal = () => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const companiesState = useSelector((state: RootState) => state.companies);

  useIdleTimeout();

  const fetchCompanies = async () => {
    if (auth !== null) {
      const action = await loadCompanies(axiosPrivate, auth, companiesState);
      dispatch(action);
    }
  };

  const fetchDeviceTypes = async () => {
    const result = await DevicesApi.getDeviceTypes(axiosPrivate, auth);
    dispatch(setDeviceTypeList(result.deviceTypes));
  };

  useEffect(() => {
    fetchCompanies();
    fetchDeviceTypes();
  }, [auth]);

  return (
    <div className={styles.portal}>
      <NavBar />
      <div className={styles.mainContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default Portal;

import React, { useEffect, useState } from 'react';
import { Label } from '../../../../aurora/typography/Label/Label';
import SelectField from '../../../../components/forms/SelectField';
import TextField from '../../../../components/forms/TextField';
import {
  required,
  validatePostalCode,
  validatePhoneNumber,
} from '../../../../components/forms/validators';
import { carrierOptions, states } from './formOptions';
import styles from './AddressSection.module.scss';
import formStyles from '../../../../components/forms/Form.module.scss';
import { Accordion } from '../../../../aurora/components/Accordion/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { AddressTypeEnum } from '../../../../state/addresses/types';
import { toAddressString } from '../../../../utils/addressUtil';
import AddressesApi from '../../../../api/addressesApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { useField, useForm } from 'react-final-form';
import { resetAddressState, setAddresses } from '../../../../state/addresses';
import { createSelector } from '@reduxjs/toolkit';
import { LinkButton } from '../../../../aurora/components/Button/LinkButton';
import {
  IconPlusCircleFill,
  IconCloseCircleFill,
} from '../../../../aurora/icons';
import Select from '../../../../aurora/components/Select/Select';
import { capitalize } from 'lodash';
import AddressAutocompleteField, {
  MapboxAddress,
} from '../../../../aurora/components/AddressAutocompleteField/AddressAutocompleteField';

interface Props {
  heading: string;
  isArtycViewer: boolean;
  addressType?: AddressTypeEnum;
  shipmentPrefix: string;
  includeTracking: boolean;
  addressAccordion?: boolean;
  trackingRequired?: boolean;
  useCompanyFromForm?: boolean;
  canSelectExistingAddress?: boolean;
}

const selectAddressesByType = createSelector(
  [
    (state: RootState) => state.addresses.data,
    (_: any, addressType?: AddressTypeEnum) => addressType,
  ],
  (addresses, addressType) =>
    addressType && addresses
      ? addresses.filter((address) => address.addressType === addressType)
      : []
);

const AddressFormSection = ({
  heading,
  isArtycViewer,
  addressType,
  shipmentPrefix,
  addressAccordion,
  includeTracking,
  trackingRequired,
  useCompanyFromForm = true,
  canSelectExistingAddress = true,
}: Props) => {
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();

  const [addNewAddress, setAddNewAddress] = useState(false);

  const companyField = useField('company', { subscription: { value: true } });
  const company = useCompanyFromForm ? companyField.input.value : undefined;

  const existingAddresses = useSelector((state: RootState) =>
    selectAddressesByType(state, addressType)
  );
  const form = useForm();

  const fetchAddresses = async (companyId: string) => {
    dispatch(resetAddressState());
    if (addressType === AddressTypeEnum.LAB) {
      const result = await AddressesApi.getLabAddresses(axios, auth, companyId);
      dispatch(setAddresses(result));
    }
    // TODO: Add other address type fetching as needed
  };

  const missingCompany =
    useCompanyFromForm && (company === '' || company === undefined);
  useEffect(() => {
    if (!useCompanyFromForm) return;

    if (missingCompany) {
      dispatch(resetAddressState());
      form.change(`${shipmentPrefix}.addressId`, undefined);
    } else if (addressType && company) {
      fetchAddresses(company as string);
    }

    return () => {
      dispatch(resetAddressState());
    };
  }, [company, useCompanyFromForm]);

  // toggling between adding new address vs selecting existing, clear the address field
  useEffect(() => {
    const formValues = form.getState().values;
    const addressValues = formValues[shipmentPrefix] || {};

    if (addNewAddress) {
      form.change(`${shipmentPrefix}.addressId`, undefined);
    } else {
      // Only clear fields if they have values
      if (addressValues.addressName) {
        form.change(`${shipmentPrefix}.addressName`, '');
      }
      if (addressValues.attn) form.change(`${shipmentPrefix}.attn`, '');
      if (addressValues.addressLine1) {
        form.change(`${shipmentPrefix}.addressLine1`, '');
      }
      if (addressValues.addressLine2) {
        form.change(`${shipmentPrefix}.addressLine2`, '');
      }
      if (addressValues.city) form.change(`${shipmentPrefix}.city`, '');
      if (addressValues.state) form.change(`${shipmentPrefix}.state`, '');
      if (addressValues.postalCode) {
        form.change(`${shipmentPrefix}.postalCode`, '');
      }
      if (addressValues.phoneNumber) {
        form.change(`${shipmentPrefix}.phoneNumber`, '');
      }
    }
  }, [addNewAddress]);

  const addressOptions = existingAddresses.map((address) => ({
    label: toAddressString(address),
    value: address._id,
  }));

  const hasExistingAddresses = addressType && existingAddresses.length > 0;

  const getTypeSpecificFields = () => {
    if (!addressType) return null;

    switch (addressType) {
      case AddressTypeEnum.LAB:
        return (
          <>
            <TextField
              label="Lab Name"
              name={`${shipmentPrefix}.addressName`}
              showOptional
            />
            <TextField
              label="Attn"
              name={`${shipmentPrefix}.attn`}
              showOptional
            />
          </>
        );
      case AddressTypeEnum.CAMPUS:
        return (
          <>
            <TextField
              label="Name/Label"
              name={`${shipmentPrefix}.addressName`}
              validate={required}
            />
            <TextField
              label="Building/Department"
              name={`${shipmentPrefix}.buildingDepartment`}
              showOptional
            />
            <TextField
              label="Attn"
              name={`${shipmentPrefix}.attn`}
              showOptional
            />
          </>
        );
      case AddressTypeEnum.WAREHOUSE:
        return (
          <>
            <TextField
              label="Name/Label"
              name={`${shipmentPrefix}.addressName`}
              validate={required}
            />
            <TextField
              label="Attn"
              name={`${shipmentPrefix}.attn`}
              showOptional
            />
          </>
        );
      default:
        return (
          <TextField
            label="Name/Label"
            name={`${shipmentPrefix}.addressName`}
            showOptional
          />
        );
    }
  };

  const handleAddressSelect = (address: MapboxAddress) => {
    form.batch(() => {
      form.change(`${shipmentPrefix}.addressLine1`, address.addressLine1);
      if (address.addressLine2) {
        form.change(`${shipmentPrefix}.addressLine2`, address.addressLine2);
      }
      form.change(`${shipmentPrefix}.city`, address.city);
      form.change(`${shipmentPrefix}.state`, address.state);
      form.change(`${shipmentPrefix}.postalCode`, address.postalCode);
      form.change(`${shipmentPrefix}.latitude`, address.latitude);
      form.change(`${shipmentPrefix}.longitude`, address.longitude);
    });
  };

  const addressFragment =
    canSelectExistingAddress && hasExistingAddresses && !addNewAddress ? (
      <>
        <SelectField
          label={addressType === AddressTypeEnum.LAB ? 'Lab' : 'Address'}
          name={`${shipmentPrefix}.addressId`}
          disabled={missingCompany}
          placeholder={missingCompany ? 'Select Company First' : 'Select'}
          options={addressOptions}
          validate={required}
        />
        <div className={styles.addButton}>
          <LinkButton
            label={`Add New ${capitalize(addressType)} Address`}
            onClick={() => setAddNewAddress(true)}
            style="primary"
            size="md"
            iconLeft={IconPlusCircleFill}
          />
        </div>
      </>
    ) : (
      <>
        {canSelectExistingAddress && hasExistingAddresses && addNewAddress ? (
          <>
            <Select
              disabled
              label={addressType === AddressTypeEnum.LAB ? 'Lab' : 'Address'}
              options={[]}
            />
            <div className={styles.addButton}>
              <LinkButton
                label="Cancel"
                onClick={() => setAddNewAddress(false)}
                style="error"
                size="md"
                iconLeft={IconCloseCircleFill}
              />
            </div>
          </>
        ) : null}

        {getTypeSpecificFields()}

        <AddressAutocompleteField
          label="Address Line 1"
          name={`${shipmentPrefix}.addressLine1`}
          validate={required}
          onAddressSelect={handleAddressSelect}
        />

        <TextField
          label="Address Line 2"
          name={`${shipmentPrefix}.addressLine2`}
          showOptional
        />

        <TextField
          label="City"
          name={`${shipmentPrefix}.city`}
          validate={required}
        />

        <div className={formStyles.formRow}>
          <SelectField
            label="State"
            name={`${shipmentPrefix}.state`}
            options={states}
            validate={required}
          />

          <TextField
            label="Postal Code"
            name={`${shipmentPrefix}.postalCode`}
            validate={validatePostalCode}
          />
        </div>
        <div className={formStyles.formRow}>
          <TextField
            label="Phone Number"
            name={`${shipmentPrefix}.phoneNumber`}
            showOptional
            validate={validatePhoneNumber}
          />
        </div>
      </>
    );

  return (
    <>
      <Label size="sm" className={styles.heading}>
        {heading}
      </Label>

      <div className={formStyles.form}>
        {!addressAccordion ? addressFragment : null}

        {isArtycViewer && includeTracking ? (
          <>
            <div className={formStyles.formRow}>
              <TextField
                label="Tracking Number"
                name={`${shipmentPrefix}.trackingNumber`}
                showOptional={!trackingRequired}
                validate={trackingRequired ? required : undefined}
                tooltip="If available, enter the tracking number now. Otherwise, you can add it later"
              />
              <SelectField
                label="Carrier"
                name={`${shipmentPrefix}.carrier`}
                options={carrierOptions}
                showOptional={!trackingRequired}
                validate={trackingRequired ? required : undefined}
                clearable
              />
            </div>
          </>
        ) : null}

        {addressAccordion ? (
          <Accordion
            className={styles.addressAccordion}
            sections={[
              {
                header: 'Address Information',
                body: <div className={formStyles.form}>{addressFragment}</div>,
              },
            ]}
          />
        ) : null}
      </div>
    </>
  );
};

export default AddressFormSection;

import React from 'react';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  StylesConfig,
  GroupBase,
} from 'react-select';
import { useDripsyTheme, useSx } from 'dripsy';
import { IconChevronUp, IconChevronDown } from '../../icons';
import { BaseTheme } from '../../theme/baseTheme';
import InputLabel, { InputLabelProps } from '../form/InputLabel';
import InputHint, { InputHintProps } from '../form/InputHint';

export interface SelectOptionType {
  value: string;
  label: string;
}

const CustomDropdownIndicator = (
  props: DropdownIndicatorProps<
    SelectOptionType,
    false,
    GroupBase<SelectOptionType>
  >
) => {
  const sx = useSx();
  const iconSize = { width: 20, height: 20 };

  const isDisabled = props.selectProps.isDisabled;

  const renderIcon = () => {
    const IconComponent = props.selectProps.menuIsOpen
      ? IconChevronUp
      : IconChevronDown;
    return (
      <IconComponent
        {...iconSize}
        style={sx({ color: isDisabled ? 'neutral700' : 'gray50v1' })}
      />
    );
  };

  return (
    <components.DropdownIndicator {...props}>
      {renderIcon()}
    </components.DropdownIndicator>
  );
};

const useSelectStyles = (
  variant: keyof BaseTheme['select'],
  error?: boolean
): StylesConfig<SelectOptionType, false, GroupBase<SelectOptionType>> => {
  const sx = useSx();
  const { theme } = useDripsyTheme();
  const variantSx = theme.select[variant];

  const commonFontStyles = {
    fontSize: '14px',
    ...sx({ color: 'gray700v1' }),
  };

  const textStyles = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };

  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      ...commonFontStyles,
      height: '40px',
      borderRadius: '6px',
      borderWidth: '1px',
      borderStyle: 'solid',
      padding: '10px 14px',
      pointerEvents: 'auto',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      ...sx({
        backgroundColor: state.isDisabled ? 'gray50' : 'white',
        borderColor: state.isDisabled
          ? 'neutral500'
          : state.menuIsOpen || state.isFocused
          ? variantSx.$active.borderColor
          : error
          ? 'red500'
          : 'neutral500',
      }),
      '&:hover': {
        ...sx({
          borderColor: state.isDisabled
            ? variantSx.borderColor
            : error
            ? 'red500'
            : 'primary600',
        }),
      },
      boxShadow: state.isFocused
        ? error
          ? '0px 0px 0px 3px rgba(255, 0, 0, 0.1)'
          : '0px 0px 0px 3px #B2D5FF'
        : 'none',
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: '0px',
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      padding: '0px',
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      padding: '0px',
      margin: '0px',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      ...textStyles,
      ...sx({
        color: state.isDisabled ? 'gray300' : variantSx.color,
        fontWeight: variantSx.fontWeight,
      }),
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      ...textStyles,
      ...sx({
        color: state.isDisabled ? 'gray300' : variantSx.$placeholder.color,
        fontWeight: variantSx.fontWeight,
      }),
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1001, // higher than modal's z-index of 1000
      fontFamily: 'Inter, sans-serif',
      '& .select__menu': {
        fontFamily: 'Inter, sans-serif',
      },
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      border: 'none',
      borderRadius: '6px',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.04)',
      zIndex: 1001, // higher than modal's z-index of 1000
      ...sx({
        backgroundColor: 'white',
      }),
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: '168px',
      margin: '4px 4px 4px 0',
      padding: '0px',

      // scrollbar styles
      '::-webkit-scrollbar': {
        width: '6px',
        marginRight: '4px',
      },
      '::-webkit-scrollbar-track': {
        ...sx({ backgroundColor: 'neutral500' }),
        borderRadius: '3px',
        border: 'none',
      },
      '::-webkit-scrollbar-thumb': {
        ...sx({ backgroundColor: 'gray50v1' }),
        borderRadius: '3px',
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      ...commonFontStyles,
      ...textStyles,
      width: 'calc(100% - 4px)',
      ...sx({
        backgroundColor: state.isSelected
          ? 'primary50'
          : state.isFocused
          ? 'neutral500'
          : baseStyles.backgroundColor,
        padding: '10px 16px',
      }),
    }),
  };
};

export type SelectProps = {
  options: SelectOptionType[];
  placeholder?: string;
  disabled?: boolean;
  onChange?: (option: SelectOptionType) => void;
  managedValue?: string | number | null;
  className?: string;
  variant?: keyof BaseTheme['select'];
  name?: string;
  clearable?: boolean;
} & InputLabelProps &
  InputHintProps;

const Select = ({
  options,
  placeholder = 'Select',
  label,
  disabled,
  onChange,
  className,
  managedValue,
  variant = 'form',
  tooltip,
  showRequired,
  showOptional,
  name,
  hint,
  error,
  clearable = false,
}: SelectProps) => {
  const variantStyles = useSelectStyles(variant, error);

  const id =
    name !== undefined
      ? name
      : label
      ? label.toLowerCase().replace(/[^a-z0-g]/g, '-')
      : undefined;

  return (
    <div className={className}>
      <InputLabel
        id={id}
        label={label}
        showOptional={showOptional}
        showRequired={showRequired}
        tooltip={tooltip}
      />
      <ReactSelect
        id={id}
        isDisabled={disabled}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        theme={(theme) => ({
          ...theme,
        })}
        options={options}
        placeholder={placeholder}
        styles={variantStyles}
        value={options.find((option) => option.value === managedValue) || null}
        onChange={(newValue) => {
          if (newValue && onChange) {
            onChange(newValue);
          }
        }}
        name={name}
        isClearable={clearable}
        menuPosition="fixed"
        menuPortalTarget={document.body}
      />
      <InputHint hint={hint} error={error} disabled={disabled} />
    </div>
  );
};

export default Select;

import { SegmentSnapshotEntity } from '../../state/segmentSnapshots/types';
import { DeviceType } from '../../state/devices/types';
import { getSnapshotInternalTemp } from '../segmentSnapshot/data';

// MKT = ΔH/R * (-ln(Σ(e^(-ΔH/RT))/n))
// Where ΔH = 83.144 kJ/mol (activation energy)
// R = 0.0083144 kJ/mol/K (gas constant)
const deltaH = 83.144;
const R = 0.0083144;
export const calculateMeanKineticTemp = (
  snapshots: SegmentSnapshotEntity[],
  shipperType: DeviceType
): number | undefined => {
  if (snapshots.length === 0) return undefined;
  if (snapshots.length === 1) {
    return getSnapshotInternalTemp(snapshots[0], shipperType);
  }

  // Sort snapshots by time
  const sortedSnapshots = [...snapshots].sort(
    (a, b) => a.secSinceStart - b.secSinceStart
  );

  // Get temperatures and time intervals
  const temperatures = sortedSnapshots.map((s) =>
    getSnapshotInternalTemp(s, shipperType)
  );
  const temperaturesK = temperatures.map((t) => t + 273.15);

  // first snapshot's delta time is 0
  const timeIntervals = sortedSnapshots.map((_, i) =>
    i === 0
      ? 0
      : sortedSnapshots[i].secSinceStart - sortedSnapshots[i - 1].secSinceStart
  );

  // Calculate exp values and sums
  const expValues = temperaturesK.map(
    (tempK, i) => Math.exp(-deltaH / (R * tempK)) * timeIntervals[i]
  );

  const sumExpValues = expValues.reduce((a, b) => a + b, 0);
  const sumTimeIntervals = timeIntervals.reduce((a, b) => a + b, 0);

  // Calculate MKT
  const mktK = -deltaH / R / Math.log(sumExpValues / sumTimeIntervals);

  // Convert back to Celsius and round to 2 decimal places
  return Math.round((mktK - 273.15) * 100) / 100;
};

import React, { forwardRef } from 'react';
import { IconLogo } from '../../../../aurora/icons';
import { JourneyEntity } from '../../../../state/journeys/types';
import { Heading } from '../../../../aurora/typography/Heading/Heading';
import { Text } from '../../../../aurora/typography/Text/Text';
import { Label } from '../../../../aurora/typography/Label/Label';
import styles from './JourneyReport.module.scss';
import SnapshotsGraph from '../journeyPage/graph/SnapshotsGraph';
import { SegmentEntity } from '../../../../state/segments/types';
import { SegmentSnapshotEntity } from '../../../../state/segmentSnapshots/types';
import SnapshotsTable from './SnapshotsTable';
import { ShippingProfileEntity } from '../../../../state/shippingProfile/types';
import { EventEntity } from '../../../../state/events/types';
import CoolingSummary from './CoolingSummary';
import { calculateTempThresholds } from '../../../../utils/temp/tempUtil';
import EventsTable from './EventsTable';

interface Props {
  journey: JourneyEntity;
  segment: SegmentEntity;
  snapshots: SegmentSnapshotEntity[];
  isArtycViewer: boolean;
  events: EventEntity[];
  shippingProfile: ShippingProfileEntity | undefined;
}

const JourneyReport = forwardRef<HTMLDivElement, Props>(
  (
    { journey, segment, snapshots, isArtycViewer, events, shippingProfile },
    ref
  ) => {
    const tempThresholdsC = calculateTempThresholds(shippingProfile, segment);

    return (
      <div className={styles.report} ref={ref}>
        <div className={styles.logo}>
          <IconLogo width={142} height={36} color={'primary600'} />
        </div>

        <Heading size="h5">Journey {journey.pid}</Heading>

        <CoolingSummary
          segment={segment}
          snapshots={snapshots}
          tempThresholdsC={tempThresholdsC}
        />

        <div className={styles.graphContainer}>
          <SnapshotsGraph
            segment={segment}
            snapshots={snapshots}
            tempUnit={'C'}
            isArtycViewer={isArtycViewer}
            showLidOpen={true}
            showDateAsDuration={true}
            externalTempData={[]}
            tempThresholdsC={tempThresholdsC}
            className={styles.graph}
          />
        </div>

        <EventsTable events={events} />

        <SnapshotsTable
          snapshots={snapshots}
          shipperType={segment.deviceType}
        />

        <div className={styles.contact}>
          <Label size="sm">Have questions? Let us know.</Label>
          <Text size="sm">Emily Harari</Text>
          <Text size="sm">Customer Success</Text>
          <Text size="sm">support@shipartyc.com</Text>
        </div>
      </div>
    );
  }
);

JourneyReport.displayName = 'JourneyReport';

export default JourneyReport;

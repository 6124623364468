import {
  convertTemp,
  mkDatapoint,
} from '../../../pages/journeys/components/journeyPage/graph/chartOptions';
import { DeviceType } from '../../../state/devices/types';
import { Medstow5LISnapshotEntity } from '../../../state/segmentSnapshots/medstow5LI';
import { SegmentSnapshotEntity } from '../../../state/segmentSnapshots/types';
import { SnapshotDataFetch } from './types';

export const isMedstow5LISnapshot = (
  snapshot: SegmentSnapshotEntity,
  deviceType: DeviceType
): snapshot is Medstow5LISnapshotEntity => {
  return deviceType === DeviceType.Medstow5LI;
};

export const medstow5LIFetch: SnapshotDataFetch<Medstow5LISnapshotEntity> = {
  getInternalTemp: (snapshot) => snapshot.temps.internalC,
  getExternalTemp: (snapshot) => snapshot.temps.externalC,
  getSoc: (snapshot) => (snapshot.battery1Soc + snapshot.battery2Soc) / 2,
  getChartedData: (snapshots, isArtycViewer, tempUnit) => {
    return [
      {
        label: `External (°${tempUnit})`,
        data: snapshots.map((snapshot) =>
          mkDatapoint(snapshot, convertTemp(tempUnit)(snapshot.temps.externalC))
        ),
        borderColor: '#eea23e',
        yAxisID: 'temperature',
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#eea23e',
      },
      {
        label: `Internal (°${tempUnit})`,
        data: snapshots.map((snapshot) =>
          mkDatapoint(snapshot, convertTemp(tempUnit)(snapshot.temps.internalC))
        ),
        borderColor: '#0c77ff',
        yAxisID: 'temperature',
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#0c77ff',
      },
      {
        label: 'Battery 1 (%)',
        data: snapshots.map((snapshot) =>
          mkDatapoint(snapshot, snapshot.battery1Soc)
        ),
        borderColor: '#5a6473',
        yAxisID: 'temperature',
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#5a6473',
      },
      {
        label: 'Battery 2 (%)',
        data: snapshots.map((snapshot) =>
          mkDatapoint(snapshot, snapshot.battery2Soc)
        ),
        borderColor: '#99A0AE',
        yAxisID: 'temperature',
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#99A0AE',
      },
    ];
  },
};
